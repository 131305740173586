import React from 'react';
import { Grid, Button, Box, useMediaQuery, useTheme } from '@mui/material';

const HoleSelectionGrid = ({ holes, onHoleSelect }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const buttonStyle = {
    aspectRatio: '1 / 1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: { xs: '0.8rem', sm: '1rem' },
    ...(isDesktop && {
      maxWidth: '160px',
      maxHeight: '160px',
      minWidth: '180px',
      minHeight: '160px',
    }),
  };

  return (
    <Box sx={{ 
      maxWidth: isDesktop ? '900px' : '100%', 
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Box>
        <Grid container spacing={isDesktop ? 1.5 : 2} justifyContent="center">
          {holes.slice(0, 16).map((hole) => (
            <Grid item xs={3} key={hole.hole_number}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => onHoleSelect(hole.hole_number)}
                sx={buttonStyle}
              >
                {hole.hole_number}
              </Button>
            </Grid>
          ))}
        </Grid>
        {holes.length > 16 && (
          <Grid container spacing={isDesktop ? 1 : 2} justifyContent="center" sx={{ mt: 2 }}>
            {holes.slice(16).map((hole) => (
              <Grid item xs={3} key={hole.hole_number}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => onHoleSelect(hole.hole_number)}
                  sx={buttonStyle}
                >
                  {hole.hole_number}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default HoleSelectionGrid;
