import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { EmailClient, EmailMessage, KnownEmailSendStatus } from '@azure/communication-email';

const CONNECTION_STRING = "endpoint=https://proleaguestudio-smtp.unitedstates.communication.azure.com/;accesskey=DiHlOnvTFhjpcPRel3uu6TNdJegB7e6J9cQuzPjYTKiNzYw520ItJQQJ99AKACULyCpqEXQTAAAAAZCSvdVs";
const emailClient = new EmailClient(CONNECTION_STRING);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://plnapi-dev.azurewebsites.net';

const apiInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true
});

let sessionCredentials = null;
let refreshInterval;
let lastRefreshTime = 0;
const REFRESH_COOLDOWN = 60000; // 1 minute cooldown
export const refreshToken = async () => {
  const currentTime = Date.now();
  if (currentTime - lastRefreshTime < REFRESH_COOLDOWN) {
    console.log('Skipping refresh due to cooldown');
    return;
  }

  const storedToken = localStorage.getItem('token');
  if (!storedToken) {
    throw new Error('No stored credentials or token for refresh');
  }

  // Check token expiry logic here (if applicable)
  if (isTokenExpired(storedToken)) {
    throw new Error('Token is expired');
  }

  try {
    const newToken = await login(sessionCredentials);
    lastRefreshTime = currentTime;
    return newToken;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

// Example utility to check if the token is expired
const isTokenExpired = (token) => {
  // Logic to decode the token and check its expiry
  // You can use libraries like jwt-decode to help with this
  return false; // Placeholder; implement actual check
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

const startTokenRefresh = () => {
  // Refresh every 25 minutes
  refreshInterval = setInterval(async () => {
    try {
      await refreshToken();
      console.log('Token refreshed successfully');
    } catch (error) {
      console.error('Failed to refresh token:', error);
      // Handle refresh failure (e.g., redirect to login)
    }
  }, 25 * 60 * 1000);
};

const stopTokenRefresh = () => {
  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
};

const keepAliveCall = async () => {
  if (!isAuthenticated()) return;

  try {
    await apiInstance.get('/api/venues/');
    console.log('Keep-alive call successful');
  } catch (error) {
    console.error('Keep-alive call failed:', error);
    // The error will be handled by the existing interceptor if it's a 500
  }
};

let keepAliveInterval;

const startSessionMaintenance = () => {
  if (!isAuthenticated()) return;
  
  startTokenRefresh(); // Start token refresh process
  // Start keep-alive process
  keepAliveInterval = setInterval(keepAliveCall, 20 * 60 * 1000); // Every 20 minutes
};

export const stopSessionMaintenance = () => {
  stopTokenRefresh(); // Stop token refresh process
  // Stop keep-alive process
  if (keepAliveInterval) {
    clearInterval(keepAliveInterval);
    keepAliveInterval = null;
  }
};

export const login = async (credentials) => {
  try {
    // Make the POST request to the login API endpoint with user credentials
    const response = await apiInstance.post('/api/auth/login', credentials);
    
    // Log the response to check its structure during debugging
    console.log('Login Response:', response.data);

    // If the response contains an array, access the first element for the token and other data
    const data = Array.isArray(response.data) ? response.data[0] : response.data;

    // Extract the necessary data from the response
    const token = data.access_token;  // Access the JWT token
    const playerId = data.PlayerId;   // Access PlayerId from the response
    const roleId = data.roleId;       // Access RoleId from the response
    const roleName = data.roleName;   // Access RoleName from the response

    // If the token is received, proceed with storing it in localStorage
    if (token) {
      // Store the token in localStorage for session management
      localStorage.setItem('token', token);
      
      // Store session credentials (optional, if needed for session maintenance)
      sessionCredentials = { ...credentials };  // Store login credentials for later use
      
      // Optionally, store additional details like PlayerId, RoleId, and RoleName in localStorage
      localStorage.setItem('playerId', playerId);  // Store the player's ID
      localStorage.setItem('roleId', roleId);      // Store the user's role ID
      localStorage.setItem('roleName', roleName);  // Store the user's role name

      // Start session maintenance (this may include refreshing tokens periodically)
      startSessionMaintenance();

      // Return the token (or any other data if needed)
      return token;
    } else {
      // If no token is received, throw an error
      throw new Error('No token received from server');
    }
  } catch (err) {
    // Log the error message in case of failure
    console.error('Login error:', err.response ? err.response.data : err.message);

    // Re-throw the error so it can be handled in the calling function
    throw err;
  }
};


export const changePassword = async (oldPassword, newPassword) => {
  try {
    // Ensure that oldPassword and newPassword are provided
    if (!oldPassword || !newPassword) {
      throw new Error("Both old and new passwords are required");
    }

    const response = await apiInstance.post('/api/auth/change_password', {
      OldPassword: oldPassword,
      NewPassword: newPassword,
    });

    console.log('Password change successful:', response.data);
    return response.data; // Assuming a success message or status in response
  } catch (error) {
    // More detailed error logging
    console.error('Error changing password:', error.response ? error.response.data : error.message);
    
    // Throw a custom error with the response message or a generic message
    throw new Error(error.response ? error.response.data.message : 'Password change failed');
  }
};

// apiService.js
export const resetPassword = async (email, newPassword) => {
  try {
    const response = await apiInstance.post('/api/auth/reset_password', {
      email: email,
      new_password: newPassword,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to reset password');
  }
};



export const forgotPassword = async (email) => {
  try {
    // Prepare the email content
    const message = {
      senderAddress: "DoNotReply@a3491a7e-2d6a-4aee-bd4c-6b31671e25d5.azurecomm.net",  // Replace with your verified sender email
      content: {
        subject: "Password Reset Request",
        plainText: "Please click the link below to reset your password.",
        html: `<p>Please click the link below to reset your password.</p>
               <p><a href='${process.env.REACT_APP_BASE_URL}/reset-password?email=${email}'>Reset Password</a></p>`  // Use REACT_APP_BASE_URL
      },
      recipients: {
        to: [
          {
            address: email,
            displayName: "User"
          }
        ]
      }
    };

    // Send the email
    const poller = await emailClient.beginSend(message);
    const response = await poller.pollUntilDone();

    if (response.status !== KnownEmailSendStatus.Succeeded) {
      throw new Error('Failed to send password reset email');
    }

    return response;
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
};


export const logout = () => {
  stopSessionMaintenance();
  // Clear ALL localStorage items
  localStorage.clear();  // Instead of removing items one by one
  // Or if we want to be specific but thorough:
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('password');
  localStorage.removeItem('selectedGroup');
  localStorage.removeItem('selectedRound');
  localStorage.removeItem('currentPage');
  localStorage.removeItem('playerId');
  localStorage.removeItem('roleId');
  localStorage.removeItem('roleName');
  sessionCredentials = null;
  window.location.href = '/login';
};

apiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 500) {
      try {
        const newToken = await refreshToken();
        error.config.headers['Authorization'] = newToken;
        return apiInstance(error.config);
      } catch (refreshError) {
        // If refresh fails, log out the user
        console.error('Token refresh failed:', refreshError);
        logout();
        throw new Error('Authentication failed. Please log in again.');
      }
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor
apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchLiveScoresData = async () => { 
    try {
        const response = await apiInstance.get('/api/liveScores/');
        console.log('Fetched liveScores Data:', response.data);
        return response.data;
    } catch (err) {
        console.error('Error fetching liveScores Data:', err);
        throw err;
    }
};

//React query version of the above api call
export const useLiveScoresData = () => {
  return useQuery({
      queryKey: ['liveScores'],
      queryFn: fetchLiveScoresData,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
  });
};

export const fetchLiveScoresForTournament = async (tournamentId) => {
    try {
        const response = await apiInstance.get(`/api/liveScores/${tournamentId}`);
        // console.log('Fetched live scores for tournament:', response.data);
        return response.data;
    } catch (err) {
        console.error('Error fetching live scores:', err);
        throw err;
    }
};

export const fetchLeaderboardData = async () => {
  try {
    const response = await apiInstance.get('/api/players/leaderboard');
    console.log('Fetched leaderboard data:', response.data);
    return response.data;
  } catch (err) {
    console.error('Error fetching leaderboard data:', err);
    throw err;
  }
};

export const updateLiveScores = async (payload, tournament_id, event_id) => {
    try {
        const response = await apiInstance.patch(`/api/liveScoresAddScores/tournament/${tournament_id}/event/${event_id}`, payload);
        console.log('Update response:', response.data);
        return { success: true, data: response.data };
    } catch (error) {
        console.error('Error updating live scores:', error);
        throw error;
    }
};

//creates a scoring document in live scores using tournament ID
export const createScoringDocument = async (tournamentId) => {
  try {
    const response = await apiInstance.post(`/api/liveScores/createScoringDocument/${tournamentId}`);
    return response.data;
  } catch (error) {
    console.error('Error creating scoring document:', error);
    throw error;
  }
};

// Function to fetch driving license data
export const getDrivingLicense = async (playerId) => {
  const response = await fetch(`/api/players/${playerId}/drivingLicense`);
  if (!response.ok) throw new Error('Failed to fetch driving license');
  return await response.json();
};


export const uploadDrivingLicense = async (playerId, drivingLicenseImage) => {
  const formData = new FormData();
  formData.append('file', drivingLicenseImage); // Append the file to the form data

  try {
    const response = await apiInstance.post(`/api/players/${playerId}/upload-license`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading license:', error);
    throw error;
  }
};

export const uploadSignedDocument = async (playerId, signedDocument) => {
  const formData = new FormData();
  formData.append('file', signedDocument); // Append the file to the form data

  try {
    const response = await apiInstance.post(`/api/players/${playerId}/upload-signed-document`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading signed document:', error);
    throw error;
  }
};


export const getPlayerProfile = async (playerId) => {
  try {
    const response = await apiInstance.get(`/api/players/${playerId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching player profile:', error);
    throw error;
  }
};

export const updatePlayerProfile = async (playerId, updatedData) => {
  try {
    const response = await apiInstance.put(`/api/players/${playerId}`, updatedData);
    return response.data; // Assuming the API returns some data on success
  } catch (error) {
    console.error('Error updating player profile:', error);
    throw error; // Ensure the error is thrown to handle it in the calling function
  }
};

// Fetch all players
export const fetchPlayers = async () => {
  try {
    const response = await apiInstance.get('/api/players/');
    console.log('Fetched players data:', response.data);
    return response.data;
  } catch (err) {
    console.error('Error fetching players data:', err);
    throw err;
  }
};

export const fetchTeams = async () => {
  try {
    const response = await apiInstance.get('/api/teams/');
    console.log('Fetched players data:', response.data);
    return response.data;
  } catch (err) {
    console.error('Error fetching players data:', err);
    throw err;
  }
};

// Add Team API
export const addTeam = async (teamData) => {
  try {
    const response = await apiInstance.post('/api/teams/', teamData);
    return response.data;
  } catch (err) {
    console.error('Error adding team:', err);
    throw err;
  }
};

export const fetchSeasons = async () => {
  try {
    const response = await apiInstance.get('/api/seasons/');
    return response.data; // Assuming API returns an array of seasons
  } catch (err) {
    console.error('Error fetching seasons. Check API availability and response format:', err);
    throw err;
  }
};

export const fetchVenues = async () => {
  try {
    const response = await apiInstance.get('/api/venues/');
    return response.data; // Assuming API returns an array of venues
  } catch (err) {
    console.error('Error fetching venues:', err);
    throw err;
  }
};


export const fetchTournaments = async () => {
  try {
    const response = await apiInstance.get('/api/tournaments/');
    console.log('Fetched players data:', response.data);
    return response.data;
  } catch (err) {
    console.error('Error fetching players data:', err);
    throw err;
  }
};

export const addTournament = async (tournamentData) => {
  try {
    console.log('Adding tournament with data:', tournamentData);
    const response = await apiInstance.post('/api/tournaments/', tournamentData);
    console.log('Tournament created successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error('Error creating tournament:', err);
    throw err;
  }
};

export const fetchReferees = async () => {
  try {
    const response = await apiInstance.get('/api/referees/');
    console.log('Fetched referees data:', response.data);
    return response.data;
  } catch (err) {
    console.error('Error fetching referees data:', err);
    throw err;
  }
};

export const fetchLeagues = async () => {
  try {
    const response = await apiInstance.get('/api/leagues/');
    console.log('Fetched leagues data:', response.data);
    return response.data; // Assuming it returns an array of leagues
  } catch (err) {
    console.error('Error fetching leagues:', err);
    throw err;
  }
};

export const fetchSports = async () => {
  try {
    const response = await apiInstance.get('/api/sports/');
    console.log('Fetched sports data:', response.data); // Add logging if necessary
    return response.data; // Assuming it returns an array of sports
  } catch (err) {
    console.error('Error fetching sports:', err);
    throw err;
  }
};

export const fetchPlayersByTeamId = async (teamId) => {
  try {
    const response = await apiInstance.get(`/api/teams/${teamId}/players`);
    console.log('Fetched players data:', response.data); // Add logging if necessary
    return response.data; // Assuming it returns an array of players
  } catch (err) {
    console.error('Error fetching players data:', err);
    throw err;
  }
};

export const addReferee = async (refereeData) => {
  try {
    const response = await apiInstance.post('/api/referees/', refereeData);
    console.log('Referee added successfully:', response.data); // Add logging if needed
    return response.data;
  } catch (err) {
    console.error('Error adding referee:', err);
    throw err;
  }
};

// React query version of the fetch players function
export const usePlayersData = () => {
  return useQuery({
    queryKey: ['players'],
    queryFn: fetchPlayers,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
};

// Fetch player details by ID
export const fetchPlayerById = async (playerId) => {
  const response = await fetch(`/api/players/${playerId}`);
  if (!response.ok) throw new Error('Failed to fetch player data');
  return await response.json();
};


// Update player information
export const updatePlayer = async (playerId, updatedData) => {
  try {
    const response = await apiInstance.patch(`/api/players/${playerId}`, updatedData);
    console.log('Update player response:', response.data);
    return response.data; // Assuming the updated player data is returned
  } catch (error) {
    console.error('Error updating player:', error);
    throw error;
  }
};

// Temporary function for waiver form player updates
// TODO: Consolidate with updatePlayer function once API methods are standardized, remove admin token work around
export const updatePlayerWaiverInfo = async (playerId, updatedData) => {
  try {

    console.log('Starting player info update with:', { playerId, updatedData });
    
    const adminResponse = await apiInstance.post('/api/auth/login', {
      EmailOrUserName: 'str', 
      Password: 'string'
    });

    if (!adminResponse.data || !adminResponse.data[0].access_token) {
      console.error('No admin token received');
      return null;
    }

    console.log('Admin auth successful, proceeding with update');

    const response = await axios({
      method: 'put',
      url: `${API_BASE_URL}/api/players/${playerId}`,
      headers: {
        'accept': 'application/json',
        'Authorization': adminResponse.data[0].access_token,  // Remove Bearer prefix
        'Content-Type': 'application/json'
      },
      data: {
        PhoneNumber: updatedData.phoneNumber,
        DOB: updatedData.dateOfBirth,
        Active: true,
        IsPlaying: true,
        LeagueId: "109d3da1-b08e-49d5-8626-669250db6421",
        TeamId: "08c28ace-1011-4fd9-8aa3-0f9063bec916",
        CoachId: "string"
      }
    });

    console.log('Player info updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating player waiver info:', error);
    return null;
  }
};

// React query version of the update player function
export const useUpdatePlayer = () => {
  return useMutation({
    mutationFn: updatePlayer,
    onSuccess: (data) => {
      console.log('Player updated successfully:', data);
      // Optionally trigger a refetch of players or show a success message
    },
    onError: (error) => {
      console.error('Error updating player:', error);
      // Handle error (e.g., show an error message to the user)
    },
  });
};

export const uploadHeadshot = async (playerId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('playerId', playerId);


  const response = await apiInstance.post(`/api/players/${playerId}/upload-headshot`, formData, {
      headers: {
          'Content-Type': 'multipart/form-data',
      },
  });

  return response.data;
};


export const signup = async (userData) => {
  try {
      const response = await apiInstance.post('/api/auth/player_signup', userData); // This matches your Flask API
      return response.data; // Returns the newly created player data or success message
  } catch (error) {
      console.error('Error signing up:', error.response ? error.response.data : error.message);
      throw new Error(error.response ? error.response.data.message : 'Signup failed');
  }
};

export const updateLiveScoresWithPlayoff = async (liveScoreData) => {
    try {
        const response = await apiInstance.post('/api/liveScores/', liveScoreData);
        return response.data;
    } catch (error) {
        console.error('Error creating live score:', error);
        throw error;
    }
};

export const updateLiveScoresWinner = async (tournamentData) => {
  console.log('Update winner with payload:', JSON.stringify(tournamentData, null, 2));
  try {
    const response = await apiInstance.post('/api/liveScores/', tournamentData);
    // console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating winner:', error);
    throw error;
  }
};

//will be used for group/round creation
export const createTeam = async (teamData) => {
  const response = await apiInstance.post('/api/teams/', teamData);
  return response.data;
};

export const updatePlayerTeam = async (playerId, playerData) => {
  const response = await apiInstance.put(`/api/players/${playerId}`, playerData);
  return response.data;
};

export const addTeamToLiveScore = async (tournamentId, eventId, teamData) => {
  const response = await apiInstance.patch(`/api/liveScores/addTeams/tournament/${tournamentId}/event/${eventId}`, teamData);
  return response.data;
};

export default apiInstance;