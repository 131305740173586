// components/ForgotPassword.js

import React, { useState } from 'react';
import { forgotPassword } from '../services/apiService';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            await forgotPassword(email);
            setSuccess("Password reset link sent to your email!");
            setEmail('');
            setError('');
        } catch (err) {
            setError(err.message || "An error occurred while sending the password reset link");
        }
    };

    return (
        <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 3, border: '1px solid #ccc', borderRadius: 2 }}>
            <Typography variant="h5" component="h2" gutterBottom>
                Forgot Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    type="email"
                    label="Email Address"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                    Send Reset Link
                </Button>
            </form>
            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
            {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
        </Box>
    );
};

export default ForgotPassword;
