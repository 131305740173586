import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch, useNavigate } from 'react-router-dom';
import { AppProvider, useAuth } from './AppContext';
import LoginForm from './components/LoginForm';
import SportsSelectionPage from './pages/SportsSelectionPage';
import TournamentSelectionPage from './pages/TournamentSelectionPage';
import GroupRoundSelectionPage from './pages/GroupRoundSelectionPage';
// import TournamentManagementPage from './pages/TournamentManagementPage';
import ScoringPage from './pages/ScoringPage';
import Toolbar from './components/Toolbar';
import CenteredLoading from './components/CenteredLoading';
// import jwtDecode from 'jwt-decode';
// import { refreshToken } from './services/apiService'; 
import Leaderboard from './components/Leaderboard';
import LeaderboardCosmos from './pages/LeaderboardCosmosPage';  // Import the new component
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PlayoffSelectionPage from './pages/PlayoffSelectionPage';
import AdminPage from './pages/AdminPage'; 
import ProfilePage from './pages/ProfilePage';
import DrivingLicenseUploadPage from './pages/DrivingLicenseUploadPage';
import HeadShotUploadPage from './pages/HeadShotUploadPage';
// import PlayersList from './pages/PlayersList';
import SignupPage from './pages/SignupPage';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';
import ResetPassword from './components/ResetPassword'; // Import the ResetPassword component
import PlayersList from './components/PlayersList';
import EditPlayer from './components/EditPlayer';
import DocumentSign from './pages/DocumentSignPage';
import WaiverFormPage from './pages/WaiverFormPage';
import { getPlayerProfile } from './services/apiService';
import ThankYouPage from './pages/ThankYouPage';
import CreatePlayerForm from './components/CreatePlayerForm';
import AddTournamentPage from './pages/AddTournamentPage';
import AddTeamsPage from './pages/AddTeamsPage';
import AddRefereePage from './pages/AddRefereePage';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false, // Don't refetch on window focus/changing tabs
      retry: 1, // Retry once on a failed request
    },
  },
});

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  
  // console.log('ProtectedRoute:', { isAuthenticated, loading });

  if (loading) {
    return <CenteredLoading />;
  }
  
  if (!isAuthenticated) {
    console.log('User not authenticated, redirecting to login');
    return <Navigate to="/login" replace />;
  }

  return children;
};

const AppContent = () => {
  const { isAuthenticated, user, setUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (token) {
        setUser({ token });
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    checkToken();
  }, [setUser]);

  useEffect(() => {
    const checkPlayerStatus = async () => {
      if (!loading && isAuthenticated) {
        const roleName = localStorage.getItem('roleName');
        const playerId = localStorage.getItem('playerId');
        
        if (roleName === 'Player' && playerId) {
          try {
            const playerProfile = await getPlayerProfile(playerId);
            if (!playerProfile.SignedDocumentURL) {
              navigate('/waiver');
            }
          } catch (error) {
            console.error('Error checking player status:', error);
          }
        }
      }
    };

    checkPlayerStatus();
  }, [loading, isAuthenticated, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isAuthenticated && !['/thank-you', '/waiver'].includes(window.location.pathname) && <Toolbar />}
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/select-tournament" /> : <LoginForm />} />
        <Route path="/select-sport" element={<ProtectedRoute><SportsSelectionPage /></ProtectedRoute>} />
        <Route path="/select-tournament" element={
          <ProtectedRoute>
            {localStorage.getItem('roleName') === 'Player' ? 
              <Navigate to="/waiver" /> : 
              <TournamentSelectionPage />
            }
          </ProtectedRoute>
        } />
        <Route path="/select-group-round" element={<ProtectedRoute> <Navigate to="/select-tournament" /> </ProtectedRoute>} /> 
        <Route path="/select-group-round/:tournamentId" element={<ProtectedRoute><GroupRoundSelectionPage /></ProtectedRoute>} />
        <Route path="/select-group-round/:tournamentId/:eventId" element={<ProtectedRoute><GroupRoundSelectionPage /></ProtectedRoute>} />
        <Route path="/scoring/:tournamentId/:eventId/:teamId" element={<ProtectedRoute><ScoringPage /></ProtectedRoute>} />
        {/* <Route path="/tournament-management" element={<ProtectedRoute><TournamentManagementPage /></ProtectedRoute>} /> */}
        <Route path="/leaderboard" element={<ProtectedRoute><Leaderboard /></ProtectedRoute>} />
        <Route path="/leaderboard-cosmos" element={<ProtectedRoute><LeaderboardCosmos /></ProtectedRoute>} />
        <Route path="/playoff-selection" element={<ProtectedRoute><PlayoffSelectionPage /></ProtectedRoute>} />
        <Route path="/admin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path="/upload-license" element={<ProtectedRoute><DrivingLicenseUploadPage /></ProtectedRoute>} />
        <Route path="/headshot-upload" element={<ProtectedRoute><HeadShotUploadPage /></ProtectedRoute>} />
        <Route path="/document-sign" element={<ProtectedRoute><DocumentSign /></ProtectedRoute>} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/players-list" element={<PlayersList />} />
        <Route path="/waiver" element={<WaiverFormPage />} />
        <Route path="/create-player" element={<CreatePlayerForm />} />
        {/* <Route path="/players"  element={PlayersList} />
        <Route path="/players/edit/:playerId" element={EditPlayer} /> */}
        <Route path="/players" element={<PlayersList />} />
        <Route path="/players/edit/:playerId" element={<EditPlayer />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/add-tournament" element={<AddTournamentPage />} />
        <Route path="/add-team" element={<AddTeamsPage />} />
        <Route path="/add-referee" element={<AddRefereePage />} />
        <Route path="/" element={<Navigate to={isAuthenticated ? "/select-tournament" : "/login"} />} />
        <Route path="*" element={<Navigate to="/" />} />        
      </Routes>
    </>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppProvider>
          <AppContent />
        </AppProvider>
      </Router>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
