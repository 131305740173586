import { useQuery } from '@tanstack/react-query';
import { fetchLiveScoresForTournament } from '../services/apiService';

export const useTournamentLiveScoresData = (tournamentId) => {
  return useQuery({
    queryKey: ['tournament', tournamentId],
    queryFn: () => fetchLiveScoresForTournament(tournamentId),
    enabled: !!tournamentId,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
};