// src/pages/LeaderboardCosmos.js
import React from 'react';
import { Container, Typography } from '@mui/material';
import LeaderboardCosmosdb from '../components/LeaderboardCosmosdb';

const LeaderboardCosmos = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        CosmosDB Leaderboard
      </Typography>
      <LeaderboardCosmosdb />
    </Container>
  );
};

export default LeaderboardCosmos;

