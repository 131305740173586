import React from 'react';
import SignupForm from '../components/SignupForm'; // Adjust the path according to your directory structure
import { Container } from '@mui/material';

const SignupPage = () => {
    return (
        <Container component="main" maxWidth="xs">
            <SignupForm />
        </Container>
    );
};

export default SignupPage;
