import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fetchLiveScoresData } from '../services/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  headerCell: {
    backgroundColor: '#005A31',
    color: 'white',
    fontWeight: 'bold',
    padding: '8px',
  },
  playerName: {
    fontWeight: 'bold',
  },
  goodScore: {
    color: '#005A31',
    fontWeight: 'bold',
  },
  badScore: {
    color: '#B22222',
    fontWeight: 'bold',
  },
  neutralScore: {
    color: '#808080',
  },
  cell: {
    padding: '8px',
  },
  select: {
    margin: '16px',
  },
  button: {
    margin: '16px',
  },
}));

const Leaderboard = () => {
  const classes = useStyles();
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [liveScores, setLiveScores] = useState([]);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [showHoleScores, setShowHoleScores] = useState(false);

  const fetchData = async () => {
    try {
      const data = await fetchLiveScoresData();
      setTournaments(data);
    } catch (err) {
      console.error('Error fetching tournaments:', err);
    }
  };

  const fetchScores = async (eventId) => {
    // Safeguard against undefined tournament or events array
    const tournament = tournaments.find(t => t?.events?.some(event => event.event_id === eventId));
  
    if (tournament) {
      const event = tournament.events.find(event => event.event_id === eventId);
      if (event) {
        const sortedScores = event?.sport_details?.regulation_scoring?.slice().sort((a, b) => a.total_score - b.total_score);
        setLiveScores(sortedScores || []); // Default to empty array if sortedScores is undefined
      }
    } else {
      setLiveScores([]); // Ensure liveScores is always set to an array
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (autoRefresh && selectedEvent) {
      const id = setInterval(() => {
        fetchScores(selectedEvent);
      }, 10000); // Refresh every 10 seconds
      setIntervalId(id);
      return () => clearInterval(id);
    }
  }, [selectedEvent, autoRefresh]);

  const handleTournamentChange = (event) => {
    const tournamentId = event.target.value;
    setSelectedTournament(tournamentId);
    setSelectedEvent(''); // Reset selected event
  };

  const handleEventChange = (event) => {
    const eventId = event.target.value;
    setSelectedEvent(eventId);
    fetchScores(eventId);
  };

  const handleStart = () => {
    setAutoRefresh(true);
  };

  const handleStop = () => {
    setAutoRefresh(false);
  };

  const teamsMap = {};
  (tournaments || []).forEach(tournament => {
    (tournament?.events || []).forEach(event => {
      (event?.teams || []).forEach(team => {
        if (team?.team_id) {
          teamsMap[team.team_id] = team.team_name;
        }
      });
    });
  });
  return (
    <div>
      <FormControl className={classes.select} fullWidth>
        <InputLabel>Select Tournament</InputLabel>
        <Select
          value={selectedTournament}
          onChange={handleTournamentChange}
          label="Select Tournament"
        >
          {tournaments.map(tournament => (
            <MenuItem key={tournament.tournament_id} value={tournament.tournament_id}>
              {tournament.tournament_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedTournament && (
        <FormControl className={classes.select} fullWidth>
          <InputLabel>Select Event</InputLabel>
          <Select
            value={selectedEvent}
            onChange={handleEventChange}
            label="Select Event"
          >
            {tournaments
              .find(tournament => tournament.tournament_id === selectedTournament)
              .events.map(event => (
                <MenuItem key={event.event_id} value={event.event_id}>
                  {event.event_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <Button className={classes.button} variant="contained" color="primary" onClick={handleStart}>
        Start Refresh
      </Button>
      <Button className={classes.button} variant="contained" color="secondary" onClick={handleStop}>
        Stop Refresh
      </Button>
      <FormControlLabel
        control={
          <Checkbox
            checked={showHoleScores}
            onChange={(e) => setShowHoleScores(e.target.checked)}
            color="primary"
          />
        }
        label="Show Hole Scores"
      />
      <TableContainer component={Paper} className={classes.root}>
        <Typography variant="h6" align="center" style={{ padding: '16px', backgroundColor: '#005A31', color: 'white' }}>
          Pro Mini Golf Tournament Leaderboard
        </Typography>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Player Name</TableCell>
              <TableCell className={classes.headerCell}>Team Name</TableCell>
              <TableCell className={classes.headerCell} align="center">Total Score</TableCell>
              <TableCell className={classes.headerCell} align="center">From Par</TableCell>
              <TableCell className={classes.headerCell} align="center">Holes Completed</TableCell>
              {showHoleScores && Array.from({ length: 18 }, (_, index) => (
                <TableCell key={index} className={classes.headerCell} align="center">
                  Hole {index + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {liveScores.map((row) => (
              <TableRow key={row.player_id}>
                <TableCell className={`${classes.cell} ${classes.playerName}`}>{row.player_name}</TableCell>
                <TableCell className={classes.cell}>{teamsMap[row.team_id] || 'N/A'}</TableCell>
                <TableCell align="center" className={classes.cell}>{row.total_score}</TableCell>
                <TableCell align="center" className={`${classes.cell} ${row.from_par < 0 ? classes.goodScore : row.from_par > 0 ? classes.badScore : classes.neutralScore}`}>
                  {row.display_par}
                </TableCell>
                <TableCell align="center" className={classes.cell}>{row.holes_completed}</TableCell>
                {showHoleScores && Array.from({ length: 18 }, (_, index) => {
                  const hole = row.holes.find(h => h.hole_number === index + 1);
                  return (
                    <TableCell key={index} align="center" className={classes.cell}>
                      {hole ? hole.score : 'N/A'}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Leaderboard;
