import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Typography, Box, TextField, Button, CircularProgress, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { login, stopSessionMaintenance, getPlayerProfile } from '../services/apiService';
import { useAuth, useLoadingError } from '../AppContext';
import str33tLogo from '../assets/str33t_logo.png';

const Logo = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const LoginForm = () => {
    const [credentials, setCredentials] = useState({
        EmailOrUserName: '',
        Password: ''
    });
    const { setUser } = useAuth();
    const { loading, error, setLoading, setError } = useLoadingError();
    const navigate = useNavigate();

    useEffect(() => {
        stopSessionMaintenance();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const token = await login(credentials);
            localStorage.setItem('token', token);
            setUser({ token });

            const roleName = localStorage.getItem('roleName');
            const playerId = localStorage.getItem('playerId');
            
            if (roleName === 'Player' && playerId) {
                const playerProfile = await getPlayerProfile(playerId);
                if (playerProfile.SignedDocumentURL) {
                    navigate('/thank-you');
                    return;
                }
            }
            
            navigate('/admin');
            // navigate('/select-sport'); while it is finalized 
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please check your credentials and try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleLoginSuccess = async (response) => {
        if (response.roleName === 'Player') {
            try {
                const playerProfile = await getPlayerProfile(response.playerId);
                if (!playerProfile.SignedDocumentURL) {
                    navigate('/waiver');
                } else {
                    navigate('/thank-you'); // If they've already signed
                }
            } catch (error) {
                console.error('Error checking player status:', error);
                navigate('/waiver'); // Default to waiver if check fails
            }
        } else {
            navigate('/tournaments'); // Non-player route
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />
                <Typography component="h1" variant="h5">Sign in</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="EmailOrUserName"
                        label="Email or Username"
                        name="EmailOrUserName"
                        autoComplete="email"
                        autoFocus
                        value={credentials.EmailOrUserName}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="Password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={credentials.Password}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Sign In'}
                    </Button>
                    {error && (
                        <Typography color="error" align="center">{error}</Typography>
                    )}
                </Box>
                <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                    <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'primary.main' }}>
                        Forgot Password?
                    </Link>
                </Typography>
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Don't have an account? <Link to="/signup">Sign Up</Link>
                    </Typography>
                    
                    <Divider sx={{ width: '100%', mb: 2 }} />
                    
                    <Link 
                        to="/waiver" 
                        style={{ textDecoration: 'none' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                p: 2,
                                border: '1px solid #e0e0e0',
                                borderRadius: 1,
                                '&:hover': {
                                    backgroundColor: '#f5f5f5',
                                }
                            }}
                        >
                            <img 
                                src={str33tLogo} 
                                alt="Str33t Logo" 
                                style={{ height: '40px' }} 
                            />
                            <Typography 
                                variant="body1" 
                                color="primary" 
                                sx={{ fontWeight: 'bold' }}
                            >
                                New Str33t Athlete? Sign Up Here
                            </Typography>
                        </Box>
                    </Link>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginForm;
