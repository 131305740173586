import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Box, Typography, Grid, Paper, Alert, Snackbar, MenuItem,
} from '@mui/material';
import { fetchLeagues, fetchSports, addReferee } from '../services/apiService';
import { useNavigate } from 'react-router-dom';

const AddRefereePage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dob, setDob] = useState('');
  const [leagueId, setLeagueId] = useState('');
  const [sportId, setSportId] = useState('');
  const [leagues, setLeagues] = useState([]);
  const [sports, setSports] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // Load dropdown data
  useEffect(() => {
    const loadDropdownData = async () => {
      try {
        const leaguesData = await fetchLeagues();
        const sportsData = await fetchSports();
        setLeagues(leaguesData);
        setSports(sportsData);
      } catch (err) {
        console.error('Error fetching dropdown data:', err);
        setError('Failed to load leagues and sports. Please try again.');
      }
    };
    loadDropdownData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !email || !phoneNumber || !leagueId || !sportId) {
      setError('All required fields must be filled.');
      return;
    }

    try {
      const refereeData = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        PhoneNumber: phoneNumber,
        DOB: dob,
        LeagueId: leagueId,
        SportId: sportId,
      };
      await addReferee(refereeData);
      setSuccess(true);
      setTimeout(() => navigate('/players'), 1500); // Redirect after success
    } catch (err) {
      console.error('Error adding referee:', err);
      setError('Failed to add referee. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f9f9f9',
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: 600,
          padding: 4,
        }}
      >
        <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
          Add New Referee
        </Typography>

        {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="Date of Birth"
                InputLabelProps={{ shrink: true }}
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="League"
                value={leagueId}
                onChange={(e) => setLeagueId(e.target.value)}
                required
              >
                {leagues.map((league) => (
                  <MenuItem key={league.LeagueId} value={league.LeagueId}>
                    {league.LeagueName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Sport"
                value={sportId}
                onChange={(e) => setSportId(e.target.value)}
                required
              >
                {sports.map((sport) => (
                  <MenuItem key={sport.SportId} value={sport.SportId}>
                    {sport.SportName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Add Referee
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar
          open={success}
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Referee added successfully!
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default AddRefereePage;
