import React, { useState } from 'react';
import { Container, Typography, Box, Button, List, ListItem, ListItemIcon, Checkbox, ListItemText } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const sports = [
  { name: 'Putt Tour', checked: true },
  { name: 'CARJITSU', checked: false },
  { name: 'SLAPFIGHT', checked: false },
  { name: 'STR33T', checked: false },
];

const SportSelectionPage = () => {
  const [selectedSports, setSelectedSports] = useState(sports);

  const handleToggle = (index) => {
    const newSports = [...selectedSports];
    newSports[index].checked = !newSports[index].checked;
    setSelectedSports(newSports);
  };

  const handleUpdate = () => {
    // Logic for updating the selected sports can go here
    console.log('Selected Sports:', selectedSports.filter(sport => sport.checked));
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        {/* Title */}
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          Sport Selection
        </Typography>

        {/* List of sports with checkboxes */}
        <List>
          {selectedSports.map((sport, index) => (
            <ListItem key={sport.name} button onClick={() => handleToggle(index)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={sport.checked}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={sport.name} />
            </ListItem>
          ))}
        </List>

        {/* Update Button */}
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            sx={{
              borderRadius: '50px',
              padding: '12px 24px',
              fontWeight: 'bold',
              backgroundColor: '#1c1c1c',
              '&:hover': {
                backgroundColor: '#333',
              },
            }}
            onClick={handleUpdate}
          >
            UPDATE
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SportSelectionPage;
