import React, { useRef, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument } from 'pdf-lib';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import { uploadSignedDocument } from '../services/apiService';
import './DocumentSignPage.css';

GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';

const DocumentSignPage = () => {
  const pdfCanvasRef = useRef(null);
  const sigCanvas = useRef({});
  const [pdfURL, setPdfURL] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [viewportScale, setViewportScale] = useState(1);
  const renderTaskRef = useRef(null);

  useEffect(() => {
    const storedPlayerId = localStorage.getItem('playerId');
    if (storedPlayerId) {
      setPlayerId(storedPlayerId);
      console.log('Player ID retrieved from local storage:', storedPlayerId);
    } else {
      console.error('Player ID not found in local storage');
    }
  }, []);

  const renderPDF = async () => {
    const url = '/files/test.pdf';
    const loadingTask = getDocument(url);

    try {
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);

      const scale = 1.5;
      setViewportScale(scale);
      const viewport = page.getViewport({ scale });
      const canvas = pdfCanvasRef.current;
      const context = canvas.getContext('2d');

      // Clear previous canvas and set new dimensions
      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      // Cancel any ongoing render task before starting a new one
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      // Create a render task and store it in ref
      renderTaskRef.current = page.render({
        canvasContext: context,
        viewport: viewport,
      });

      await renderTaskRef.current.promise;
      renderTaskRef.current = null; // Clear ref after completion
    } catch (error) {
      if (error.name !== 'RenderingCancelledException') {
        console.error('Error loading PDF:', error);
        setError('Failed to load PDF. Please try again later.');
      }
    }
  };

  useEffect(() => {
    // Render PDF only when playerId is available
    if (playerId) {
      renderPDF();
    }
    
    return () => {
      // Cancel any ongoing render task on component unmount
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [playerId]);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignatureToPDF = async () => {
    if (!sigCanvas.current.isEmpty()) {
      const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      const signedPdfUrl = await addSignatureToPDF(signatureData);
      setPdfURL(signedPdfUrl);
    } else {
      alert("Please provide a signature.");
    }
  };

  const addSignatureToPDF = async (signatureData) => {
    const existingPdfBytes = await fetch('/files/test.pdf').then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const pngImageBytes = await fetch(signatureData).then(res => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(pngImageBytes);
    const pngDims = pngImage.scale(0.5);

    const canvas = pdfCanvasRef.current;
    const { width: canvasWidth, height: canvasHeight } = canvas;
    const { width: pdfWidth, height: pdfHeight } = firstPage.getSize();

    const scaleX = pdfWidth / (canvasWidth * viewportScale);
    const scaleY = pdfHeight / (canvasHeight * viewportScale);

    const signatureX = (canvasWidth - pngDims.width) * scaleX - 50;
    const signatureY = 50;

    firstPage.drawImage(pngImage, {
      x: signatureX,
      y: signatureY,
      width: pngDims.width,
      height: pngDims.height,
    });

    const pdfBytes = await pdfDoc.save();
    const signedPdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    return URL.createObjectURL(signedPdfBlob);
  };

  const uploadDocument = async () => {
    if (!playerId) {
      setError("Player ID is missing.");
      console.error("Player ID is undefined.");
      return;
    }
    if (!pdfURL) {
      setError("No signed PDF available to upload.");
      return;
    }
  
    setUploading(true);
    setError(null);
    const signedPdfBlob = await fetch(pdfURL).then(res => res.blob());
    const signedDocument = new File([signedPdfBlob], 'signed-document.pdf', { type: 'application/pdf' });
  
    try {
      await uploadSignedDocument(playerId, signedDocument);
      alert('Signed document uploaded successfully!');
    } catch (error) {
      setError('Error uploading signed document. Please try again.');
      console.error('Error uploading signed document:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="document-sign-page">
      <h2>Sign the PDF Document</h2>
      <div className="pdf-container" style={{ position: 'relative' }}>
        <canvas ref={pdfCanvasRef} className="pdf-canvas" />
        <div
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            width: '300px',
            height: '100px',
            border: '2px dashed gray',
            opacity: 0.5,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
            color: 'gray',
          }}
        >
          Sign Here
        </div>
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            className: 'signature-canvas-overlay',
            style: {
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              width: '300px',
              height: '100px'
            }
          }}
        />
      </div>
      <div className="signature-buttons">
        <button onClick={clearSignature}>Clear</button>
        <button onClick={saveSignatureToPDF}>Save to PDF</button>
        <button onClick={uploadDocument} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload Signed Document'}
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
      {pdfURL && (
        <div className="pdf-preview">
          <h3>Signed PDF Preview</h3>
          <a href={pdfURL} download="signed-document.pdf" target="_blank" rel="noopener noreferrer">
            Download Signed PDF
          </a>
          <iframe src={pdfURL} width="100%" height="500px" title="Signed PDF Preview"></iframe>
        </div>
      )}
    </div>
  );
};

export default DocumentSignPage;
