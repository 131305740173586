export const getCroppedImg = (imageSrc, pixelCrop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.crossOrigin = 'anonymous'; // Handle cross-origin issues
  
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Set canvas dimensions to the crop dimensions
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
  
        // Draw the cropped area of the image onto the canvas
        ctx.drawImage(
          image,
          pixelCrop.x, // Start cropping from x
          pixelCrop.y, // Start cropping from y
          pixelCrop.width, // Width of the crop
          pixelCrop.height, // Height of the crop
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
  
        // Convert the canvas to a blob to get the cropped image
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          const croppedImageUrl = URL.createObjectURL(blob);
          resolve(croppedImageUrl);
        }, 'image/jpeg');
      };
  
      image.onerror = (error) => reject(error);
    });
  };
  
  export default getCroppedImg;
  