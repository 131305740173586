import React, { useCallback, useState } from 'react';
import { Typography, Button, Box, Grid, IconButton, useTheme, useMediaQuery, Snackbar, Alert } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import PauseIcon from '@mui/icons-material/Pause';
import DeclareWinnerModal from './DeclareWinnerModal';
import { useNavigate } from 'react-router-dom';

const PlayerScoringCard = React.memo(({ 
  players, 
  holeNumber, 
  tournamentId, 
  eventId, 
  isPlayoffMode, 
  onBack, 
  onScoreUpdate, 
  updateScore,
  getPlayerScore,
  onHoleChange, 
  totalHoles,
  groupName,
  isUpdating,
  isPaused,
  declareWinner
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [winnerModalOpen, setWinnerModalOpen] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const navigate = useNavigate();

  // console.log('isPlayoffMode:', isPlayoffMode);

  const handleScoreUpdate = useCallback(async (player, score) => {
    await onScoreUpdate(player, holeNumber, score);
  }, [onScoreUpdate, holeNumber]);

  const handlePrevHole = () => {
    if (holeNumber > 1) {
      onHoleChange(holeNumber - 1);
    }
  };

  const handleNextHole = () => {
    if (holeNumber < totalHoles) {
      onHoleChange(holeNumber + 1);
    }
  };

  const handleDeclareWinner = async (winner) => {
    const result = await declareWinner(winner, holeNumber);
    
    setToast({
      open: true,
      message: result.message,
      severity: result.type
    });

    if (result.shouldRedirect) {
      setTimeout(() => {
        navigate('/select-tournament');
      }, 1500);
    }
  };

  const canEndPlayoff = () => {
    // Check if any player has a score for the current hole
    return players.some(player => {
      const score = getPlayerScore(player.player_id, holeNumber);
      return score !== null;
    });
  };

  const handleOpenWinnerModal = () => {
    if (!canEndPlayoff()) {
      setToast({
        open: true,
        message: 'Cannot end playoff: No scores recorded for current hole',
        severity: 'warning'
      });
      return;
    }
    setWinnerModalOpen(true);
  };

  const handleCloseToast = () => {
    setToast(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ 
      p: { xs: 2, sm: 3 },
      position: 'relative'
    }}>
      <IconButton onClick={onBack} size="small" sx={{ position: 'absolute', top: 16, left: 16 }}>
        <ArrowBackIcon />
      </IconButton>
      {isPlayoffMode && (
        <Button
          variant="contained"
          size="small"
          onClick={handleOpenWinnerModal}
          sx={{ 
            position: { xs: 'static', sm: 'absolute' },
            display: 'block',
            mb: { xs: 2, sm: 0 },
            ml: { xs: 'auto', sm: 0 },
            backgroundColor: 'green',
            '&:hover': { backgroundColor: 'darkgreen' },
            top: { sm: 24 },
            right: { sm: 24 },
            zIndex: 1
          }}
        >
          End Playoff
        </Button>
      )}
      <Typography 
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          fontSize: { 
            xs: '1.25rem',
            sm: '1.5rem',
            md: '1.75rem'
          },
          mb: 3
        }}
      >
        Hole {holeNumber}
      </Typography>
      {!navigator.onLine && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <WifiOffIcon color="warning" sx={{ mr: 1 }} />
          <Typography color="warning.main">Offline Mode</Typography>
        </Box>
      )}
      {isPaused && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
          <PauseIcon color="info" sx={{ mr: 1 }} />
          <Typography color="info.main">Updates Queued...</Typography>
        </Box>
      )}
      <Grid container spacing={isMobile ? 1 : 2} justifyContent="center">
        {players.map((player) => (
          <Grid item xs={12} key={player.player_id}>
            <Box sx={{ border: '1px solid #ccc', borderRadius: 2, p: 2, position: 'relative' }}>
              <Typography variant="subtitle1">{player.player_name}</Typography>
              <Grid container spacing={1}>
                {[1, 2, 3, 4, 5, 6].map(score => (
                  <Grid item xs={2} key={score}>
                    <Button
                      fullWidth
                      variant={getPlayerScore(player.player_id, holeNumber) === score ? "contained" : "outlined"}
                      onClick={() => handleScoreUpdate(player, score)}
                      sx={{ 
                        minWidth: 0, 
                        p: '6px',
                        position: 'relative'
                      }}
                    >
                      {score}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mt: 3,
        gap: 2  // Consistent spacing
      }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIosNewIcon />}
          onClick={handlePrevHole}
          disabled={holeNumber === 1}
          sx={{ flex: 1 }}
        >
          Previous
        </Button>
        
        <Button
          variant="contained"
          endIcon={<ArrowForwardIosIcon />}
          onClick={handleNextHole}
          disabled={holeNumber === totalHoles}
          sx={{ flex: 1 }}
        >
          Next
        </Button>
      </Box>
      
      <DeclareWinnerModal
        open={winnerModalOpen}
        onClose={() => setWinnerModalOpen(false)}
        players={players}
        currentHole={holeNumber}
        getPlayerScore={getPlayerScore}
        onDeclareWinner={handleDeclareWinner}
      />
      <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity={toast.severity} sx={{ width: '100%' }}>
          {toast.message}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default PlayerScoringCard;
