import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField, Button, Box, Typography, Grid, Paper, Alert, Snackbar, MenuItem,
} from '@mui/material';
import { fetchSeasons, fetchVenues, addTeam } from '../services/apiService';

const AddTeamPage = () => {
  const [teamName, setTeamName] = useState('');
  const [nickName, setNickName] = useState('');
  const [seasonId, setSeasonId] = useState('');
  const [homeVenueId, setHomeVenueId] = useState('');
  const [seasons, setSeasons] = useState([]);
  const [venues, setVenues] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // Fetch Seasons and Venues on Component Mount
  useEffect(() => {
    const loadDropdownData = async () => {
      try {
        const seasonsData = await fetchSeasons(); // API call to fetch seasons
        const venuesData = await fetchVenues(); // API call to fetch venues
        setSeasons(seasonsData);
        setVenues(venuesData);
      } catch (err) {
        console.error('Error fetching dropdown data:', err);
        setError('Failed to load dropdown data. Please try again.');
      }
    };
    loadDropdownData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!teamName || !seasonId || !homeVenueId) {
      setError('Team Name, Season, and Home Venue are required.');
      return;
    }

    try {
      const teamData = {
        TeamName: teamName,
        HomeVenueId: homeVenueId,
        SeasonId: seasonId,
        NickName: nickName || null,
      };

      await addTeam(teamData); // Call the API service
      setSuccess(true);
      setTimeout(() => navigate('/players'), 1500); // Redirect to the teams page after success
    } catch (err) {
      console.error('Error creating team:', err);
      setError('Failed to create the team. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f9f9f9',
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: 600,
          padding: 4,
        }}
      >
        <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
          Add New Team
        </Typography>

        {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Team Name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nickname"
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Season"
                value={seasonId}
                onChange={(e) => setSeasonId(e.target.value)}
                required
              >
                {seasons.map((season) => (
                  <MenuItem key={season.SeasonId} value={season.SeasonId}>
                    {season.SeasonName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Home Venue"
                value={homeVenueId}
                onChange={(e) => setHomeVenueId(e.target.value)}
                required
              >
                {venues.map((venue) => (
                  <MenuItem key={venue.VenueId} value={venue.VenueId}>
                    {venue.VenueName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Create Team
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar
          open={success}
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Team created successfully!
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default AddTeamPage;
