import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Grid, MenuItem } from '@mui/material';
import { getPlayerProfile } from '../services/apiService'; // Assume this function fetches the profile

const ProfilePage = () => {
  const [playerProfile, setPlayerProfile] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    Address: '',
    Suite: '',
    State: '',
    Postal: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const states = ['NY', 'CA', 'TX']; // Add more states or fetch dynamically

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Retrieve the playerId from localStorage
        const playerId = localStorage.getItem('playerId');
        if (!playerId) {
          setErrorMessage('Player ID not found.');
          return;
        }
        // Fetch the player's profile using the playerId
        const profileData = await getPlayerProfile(playerId);
        setPlayerProfile(profileData);
      } catch (error) {
        setErrorMessage('Failed to load profile data.');
      }
    };

    fetchProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlayerProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value
    }));
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Typography variant="h5" gutterBottom>
        Personal Info
      </Typography>

      {errorMessage && <Typography color="error">{errorMessage}</Typography>}

      {playerProfile ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="First Name"
              name="FirstName"
              value={playerProfile.FirstName}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Last Name"
              name="LastName"
              value={playerProfile.LastName}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="Email"
              value={playerProfile.Email}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Mobile Phone Number"
              name="PhoneNumber"
              value={playerProfile.PhoneNumber}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="Address"
              value={playerProfile.Address}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Suite"
              name="Suite"
              value={playerProfile.Suite}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              select
              label="State"
              name="State"
              value={playerProfile.State}
              onChange={handleInputChange}
              variant="outlined"
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Postal"
              name="Postal"
              value={playerProfile.Postal}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              style={{
                backgroundColor: '#1c1c1c',
                color: '#fff',
                borderRadius: '50px',
                padding: '10px 0',
                fontWeight: 'bold',
                marginTop: '1rem',
              }}
            >
              UPDATE
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Container>
  );
};

export default ProfilePage;
