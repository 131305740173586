import React, { useState, useCallback, useEffect } from 'react';
import { Container, Button, Typography, IconButton, Slider, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ImageIcon from '@mui/icons-material/Image';
import CropIcon from '@mui/icons-material/Crop';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FlipIcon from '@mui/icons-material/Flip';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Webcam from 'react-webcam';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../components/cropImage'; // Helper function to handle image cropping
import { uploadHeadshot as uploadHeadshotAPI } from '../services/apiService'; // Adjust the import path as necessary

const HeadShotUploadPage = () => {
  const [playerId, setPlayerId] = useState(null); // State for playerId
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [cropMode, setCropMode] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [flip, setFlip] = useState({ horizontal: false, vertical: false });
  const [useCamera, setUseCamera] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState(null); // Store the file object
  const webcamRef = React.useRef(null);

  // Retrieve playerId from local storage when the component mounts
  useEffect(() => {
    const storedPlayerId = localStorage.getItem('playerId');
    if (storedPlayerId) {
      setPlayerId(storedPlayerId);
      console.log('Player ID retrieved from local storage:', storedPlayerId);
    } else {
      console.error('Player ID not found in local storage');
    }
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setImagePreviewUrl(previewUrl);
      setCropMode(true);
      setFile(selectedFile);
      setUseCamera(false); // Ensure camera mode is turned off when uploading from file
    }
  };
  
  const toggleCamera = () => {
    setUseCamera((prev) => !prev);
    if (!useCamera) {
      setImagePreviewUrl(null); // Clear preview if switching back to camera
    }
  };
  


  const captureImage = () => {
    if (webcamRef.current) { // Add this check to prevent errors
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImagePreviewUrl(imageSrc);
        setCropMode(true);
        setUseCamera(false);
        fetch(imageSrc)
          .then(res => res.blob())
          .then(blob => {
            const fileName = `captured-${Date.now()}.jpg`;
            const capturedFile = new File([blob], fileName, { type: 'image/jpeg' });
            setFile(capturedFile);
          });
      }
    } else {
      console.error('Webcam is not active or not available.');
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);
  
  const handleDone = async () => {
    console.log('Player ID:', playerId); // Check if playerId is defined
    console.log('File:', file); // Check if file is defined

    if (cropMode && croppedArea) {
      const croppedImage = await getCroppedImg(imagePreviewUrl, croppedArea, rotation);
      setImagePreviewUrl(croppedImage);

      if (file) {
        try {
          await uploadHeadshotAPI(playerId, file); // Call the upload API with the file
          console.log('Headshot uploaded successfully!');
        } catch (error) {
          console.error('Error uploading headshot:', error);
        }
      } else {
        console.error('No file available for upload');
      }
    }
    setCropMode(false); // Exit crop mode
  };

  const handleFlip = (axis) => {
    setFlip((prev) => ({
      ...prev,
      [axis]: !prev[axis],
    }));
  };

  const getTransformStyles = () => {
    let transform = '';
    if (flip.horizontal) transform += 'scaleX(-1) ';
    if (flip.vertical) transform += 'scaleY(-1) ';
    transform += `rotate(${rotation}deg)`;
    return transform.trim();
  };

  return (
    <Container maxWidth="xs" sx={{ textAlign: 'center', padding: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
        <Button color="error" startIcon={<CloseIcon />} sx={{ textTransform: 'none' }}>
          Cancel
        </Button>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Edit Photo
        </Typography>
        <Button color="success" startIcon={<DoneIcon />} sx={{ textTransform: 'none' }} onClick={handleDone}>
          Done
        </Button>
      </Box>

      <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
        Head Shot for PLN marketing
      </Typography>

      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          backgroundColor: '#ccc',
          width: '100%',
          height: 0,
          paddingBottom: '100%',
          position: 'relative',
        }}
      >
        {imagePreviewUrl ? (
          cropMode ? (
            <Cropper
              image={imagePreviewUrl}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              cropShape="round"
              showGrid={false}
              style={{
                containerStyle: {
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  borderRadius: '50%',
                  transform: getTransformStyles(),
                },
              }}
            />
          ) : (
            <img
              src={imagePreviewUrl}
              alt="Headshot Preview"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                objectFit: 'cover',
                transform: getTransformStyles(),
              }}
            />
          )
        ) : useCamera ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{ facingMode: 'user' }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <IconButton
            component="label"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <ImageIcon fontSize="large" />
            <input type="file" hidden accept="image/*" onChange={handleFileChange} />
          </IconButton>
        )}
      </Box>

      {/* Editing Toolbar */}
      <Box display="flex" justifyContent="space-between" maxWidth="100%" marginTop="20px">
        <IconButton onClick={() => setUseCamera((prev) => !prev)}>
          <PhotoCameraIcon />
        </IconButton>
        <IconButton onClick={() => setCropMode((prev) => !prev)}>
          <CropIcon />
        </IconButton>
        <IconButton onClick={() => handleFlip('horizontal')}>
          <FlipIcon style={{ transform: 'scaleX(-1)' }} />
        </IconButton>
        <IconButton onClick={() => handleFlip('vertical')}>
          <FlipIcon style={{ transform: 'scaleY(-1)' }} />
        </IconButton>
        <IconButton onClick={() => setRotation((prev) => prev + 90)}>
          <RotateRightIcon />
        </IconButton>
      </Box>

      {cropMode && (
        <Box marginTop="10px">
          <Typography gutterBottom>Zoom</Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Box>
      )}

      {useCamera && (
        <Button
          variant="contained"
          color="primary"
          onClick={captureImage}
          sx={{ marginTop: '10px', textTransform: 'none' }}
        >
          Capture Photo
        </Button>
      )}
      {errorMessage && (
        <Typography color="error" sx={{ marginTop: '10px' }}>
          {errorMessage}
        </Typography>
      )}
    </Container>
  );
};

export default HeadShotUploadPage;
