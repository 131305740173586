import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Black from logo
    },
    secondary: {
      main: '#FFFFFF', // White for contrast
    },
    background: {
      default: '#F5F5F5', // Light gray for background
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem', // Larger buttons for mobile
          margin: '8px 0', // Vertical margin for spacing
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '12px 0', // Vertical margin for text fields
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        '@media (max-width: 600px)': {
          padding: '16px',
          textAlign: 'center', // Align elements vertically
        },
      },
    },
  },
});

export default theme;
