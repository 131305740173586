import React, { useState } from 'react';
import { changePassword } from '../services/apiService'; // Adjust import path as needed
import { useAuth } from '../AppContext';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';

const ChangePassword = () => {
    const { user } = useAuth(); // Assuming you have a user context
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (newPassword !== confirmPassword) {
            setError("New passwords do not match");
            return;
        }
    
        try {
            await changePassword(currentPassword, newPassword);
            setSuccess("Password changed successfully!");
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setError('');
        } catch (err) {
            setError(err.message || "An error occurred while changing the password");
        }
    };
    
    return (
        <Box sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 3, border: '1px solid #ccc', borderRadius: 2 }}>
            <Typography variant="h5" component="h2" gutterBottom>
                Change Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label="Current Password"
                    variant="outlined"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label="New Password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <TextField
                    fullWidth
                    margin="normal"
                    type="password"
                    label="Confirm New Password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
                    Change Password
                </Button>
            </form>
            {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
            {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
        </Box>
    );
};

export default ChangePassword;
