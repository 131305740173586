import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export const useGroupRoundSelection = () => {
  const queryClient = useQueryClient();

  const { data: selection } = useQuery({
    queryKey: ['groupRoundSelection'],
    queryFn: () => ({
      selectedGroup: localStorage.getItem('selectedGroup') || '',
      selectedRound: localStorage.getItem('selectedRound') || ''
    }),
    staleTime: Infinity,
  });

  const mutation = useMutation({
    mutationFn: ({ key, value }) => {
      localStorage.setItem(key, value);
      return { [key]: value };
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['groupRoundSelection'], (old) => ({
        ...old,
        ...data
      }));
    },
  });

  const setGroup = (group) => mutation.mutate({ key: 'selectedGroup', value: group });
  const setRound = (round) => mutation.mutate({ key: 'selectedRound', value: round });

  return {
    selectedGroup: selection?.selectedGroup || '',
    selectedRound: selection?.selectedRound || '',
    setGroup,
    setRound,
  };
};