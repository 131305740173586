import React, { useRef, useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import SignatureCanvas from 'react-signature-canvas';

const SignatureInput = ({ onSignatureChange }) => {
  const [signatureType, setSignatureType] = useState('typed');
  const [typedSignature, setTypedSignature] = useState('');
  const [isDrawingOpen, setIsDrawingOpen] = useState(false);
  const sigCanvas = useRef(null);

  const isCanvasEmpty = () => {
    return !sigCanvas.current?.isEmpty?.();
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleDrawingComplete = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      setIsDrawingOpen(false);
      // Notify parent component of signature change
      onSignatureChange({
        type: 'drawn',
        data: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      });
    }
  };

  const handleTypedSignatureChange = (e) => {
    const value = e.target.value;
    setTypedSignature(value);
    onSignatureChange({
      type: 'typed',
      data: value
    });
  };

  return (
    <Box sx={{ mb: 4 }}>
      
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <TextField
          fullWidth
          label="Type your signature"
          value={typedSignature}
          onChange={handleTypedSignatureChange}
          sx={{ 
            fontFamily: "'Dancing Script', cursive",
            fontSize: '1.2rem'
          }}
        />
        <IconButton 
          onClick={() => setIsDrawingOpen(true)}
          sx={{ 
            border: '1px solid',
            borderColor: 'primary.main',
            borderRadius: 1,
            p: 1
          }}
        >
          <CreateIcon />
        </IconButton>
      </Box>

      <Dialog 
        open={isDrawingOpen} 
        onClose={() => setIsDrawingOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Draw Your Signature</DialogTitle>
        <DialogContent>
          <Box 
            sx={{ 
              border: '1px solid',
              borderColor: 'grey.300',
              borderRadius: 1,
              height: 200,
              position: 'relative',
              mt: 2
            }}
          >
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{
                className: 'signature-canvas',
                style: {
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearSignature}>
            Clear
          </Button>
          <Button onClick={() => setIsDrawingOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDrawingComplete} variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview area */}
      {(typedSignature || (sigCanvas.current && !isCanvasEmpty())) && (
        <Box 
          sx={{ 
            mt: 2,
            p: 2,
            border: '1px solid',
            borderColor: 'grey.300',
            borderRadius: 1,
            minHeight: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {signatureType === 'typed' ? (
            <Typography 
              sx={{ 
                fontFamily: "'Dancing Script', cursive",
                fontSize: '1.5rem'
              }}
            >
              {typedSignature}
            </Typography>
          ) : (
            sigCanvas.current && (
              <img 
                src={sigCanvas.current.toDataURL()} 
                alt="Signature" 
                style={{ maxWidth: '100%', maxHeight: '100px' }}
              />
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default SignatureInput;