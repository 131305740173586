import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField, Button, Box, Typography, Grid, Paper, Alert, Snackbar, MenuItem,
} from '@mui/material';
import { addTournament, fetchSeasons } from '../services/apiService'; // Ensure both APIs are implemented

const AddTournamentPage = () => {
  const [tournamentName, setTournamentName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [format, setFormat] = useState('');
  const [prizes, setPrizes] = useState('');
  const [description, setDescription] = useState('');
  const [seasonId, setSeasonId] = useState('');
  const [seasons, setSeasons] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // Fetch Seasons on Component Mount
  useEffect(() => {
    const loadSeasons = async () => {
      try {
        const data = await fetchSeasons(); // API call to fetch seasons
        setSeasons(data);
      } catch (err) {
        console.error('Error fetching seasons:', err);
        setError('Failed to load seasons. Please try again.');
      }
    };
    loadSeasons();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!tournamentName || !startDate || !endDate || !format || !prizes || !description || !seasonId) {
      setError('All fields are required.');
      return;
    }

    try {
      const tournamentData = {
        TournamentName: tournamentName,
        StartDate: startDate,
        EndDate: endDate,
        Format: format,
        Prizes: prizes,
        Description: description,
        SeasonId: seasonId,
      };

      await addTournament(tournamentData); // Call the API service
      setSuccess(true);
      setTimeout(() => navigate('/players'), 1500); // Redirect to the admin page after success
    } catch (err) {
      console.error('Error creating tournament:', err);
      setError('Failed to create the tournament. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f9f9f9',
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: 600,
          padding: 4,
        }}
      >
        <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
          Add New Tournament
        </Typography>

        {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tournament Name"
                value={tournamentName}
                onChange={(e) => setTournamentName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Format"
                value={format}
                onChange={(e) => setFormat(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Prizes"
                value={prizes}
                onChange={(e) => setPrizes(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Season"
                value={seasonId}
                onChange={(e) => setSeasonId(e.target.value)}
                required
              >
                {seasons.map((season) => (
                  <MenuItem key={season.SeasonId} value={season.SeasonId}>
                    {season.SeasonName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Create Tournament
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar
          open={success}
          autoHideDuration={3000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Tournament created successfully!
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default AddTournamentPage;
