import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Box, 
  Typography 
} from '@mui/material';

const DeclareWinnerModal = ({ 
  open, 
  onClose, 
  players, 
  currentHole,
  onDeclareWinner 
}) => {
  const [selectedWinner, setSelectedWinner] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleConfirm = () => {
    const winner = players.find(p => p.player_id === selectedWinner);
    if (winner) {
      setShowConfirmation(true);
    }
  };

  const handleFinalConfirm = () => {
    const winner = players.find(p => p.player_id === selectedWinner);
    onDeclareWinner(winner);
    setShowConfirmation(false);
    setSelectedWinner('');
    onClose();
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>End Playoff Round</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Current Scores:
            </Typography>
            {players.map(player => (
              <Box key={player.player_id} sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mb: 1,
                p: 1,
                backgroundColor: player.player_id === selectedWinner ? 'rgba(0, 255, 0, 0.1)' : 'transparent',
                borderRadius: 1
              }}>
                <Typography>{player.player_name}</Typography>
                <Typography>Score: {player.total_score || 0}</Typography>
              </Box>
            ))}
          </Box>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Select Winner</InputLabel>
            <Select
              value={selectedWinner}
              onChange={(e) => setSelectedWinner(e.target.value)}
            >
              {players.map(player => (
                <MenuItem key={player.player_id} value={player.player_id}>
                  {player.player_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleConfirm}
            disabled={!selectedWinner}
          >
            Declare Winner
          </Button>
        </DialogActions>
      </Dialog>

      {/* Separate Confirmation Dialog */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ pb: 1 }}>Confirm Winner</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to declare{' '}
            <strong>{players.find(p => p.player_id === selectedWinner)?.player_name}</strong>
            {' '}as the winner at hole {currentHole}?
          </Typography>
          <Typography color="warning.main" sx={{ mt: 2 }}>
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleFinalConfirm}
          >
            Confirm Winner
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeclareWinnerModal;
