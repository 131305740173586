import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { signup } from '../services/apiService';
import { useLoadingError } from '../AppContext';

const Logo = styled('img')({
  width: '150px',
  marginBottom: '20px',
});

const SignupForm = () => {
    const [userData, setUserData] = useState({
        FirstName: '',
        LastName: '',
        Email: '',  // Updated from EmailOrUserName to Email
        Password: ''
    });
    const { loading, error, setLoading, setError } = useLoadingError();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            // Set UserName to the same value as Email
            const payload = {
                ...userData,
                UserName: userData.Email, // Take UserName from Email
            };
            const response = await signup(payload);
            console.log('Signup response:', response); // Log response to inspect data
            navigate('/login'); // Redirect to login after successful signup
        } catch (error) {
            console.error('Signup failed:', error);
            setError('Signup failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="FirstName"
                        label="First Name"
                        value={userData.FirstName}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="LastName"
                        label="Last Name"
                        value={userData.LastName}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="Email"  // Updated field name to Email
                        label="Email"
                        value={userData.Email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="Password"
                        label="Password"
                        type="password"
                        value={userData.Password}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Sign Up'}
                    </Button>
                    {error && (
                        <Typography color="error" align="center">
                            {error}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default SignupForm;
