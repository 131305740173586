import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Paper, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout, getPlayerProfile } from '../services/apiService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [waiverUrl, setWaiverUrl] = useState(null);

  useEffect(() => {
    const fetchWaiver = async () => {
      try {
        const playerId = localStorage.getItem('playerId');
        if (playerId) {
          const profile = await getPlayerProfile(playerId);
          setWaiverUrl(profile.SignedDocumentURL);
        }
      } catch (error) {
        console.error('Error fetching waiver:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWaiver();
  }, []);

  const handleReturn = () => {
    setIsLoading(true);
    logout();
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <CheckCircleIcon 
          sx={{ 
            fontSize: 60, 
            color: 'success.main',
            mb: 2 
          }} 
        />
        <Typography variant="h4" gutterBottom>
          Thank You!
        </Typography>
        <Typography variant="body1" paragraph>
          Your waiver has been successfully submitted.
        </Typography>

        <Paper elevation={3} sx={{ p: 3, mt: 2, width: '100%' }}>
          <Typography variant="body2" color="text.secondary" paragraph>
            If you need to update any information or have questions about your submission, 
            please contact a PLN administrator for assistance.
          </Typography>
          
          {waiverUrl && (
            <Button 
              variant="outlined"
              href={waiverUrl}
              target="_blank"
              sx={{ mt: 2, mb: 2 }}
              fullWidth
            >
              View Signed Waiver
            </Button>
          )}
        </Paper>

        <Button 
          variant="contained"
          onClick={handleReturn}
          sx={{ mt: 4 }}
        >
          Return to Login Page
        </Button>
      </Box>
    </Container>
  );
};

export default ThankYouPage;