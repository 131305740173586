import React, { useState, useEffect } from 'react';
import { useLiveScoresData, getPlayerProfile } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import CenteredLoading from '../components/CenteredLoading';

const TournamentSelectionPage = () => {
  const navigate = useNavigate();
  const [selectedTournament, setSelectedTournament] = useState('');
  const { data: liveScores, isLoading, error, refetch } = useLiveScoresData();

  useEffect(() => {
    if (error) {
      console.error('Error fetching live scores:', error);
      // If there's an authentication error, redirect to login
      if (error.message.includes('authentication') || error.message.includes('login')) {
        navigate('/login');
      }
    }
  }, [error, navigate]);

  useEffect(() => {
    const checkPlayerStatus = async () => {
      const roleName = localStorage.getItem('roleName');
      const playerId = localStorage.getItem('playerId');
      
      if (roleName === 'Player' && playerId) {
        try {
          const playerProfile = await getPlayerProfile(playerId);
          if (!playerProfile.SignedDocumentURL) {
            navigate('/waiver');
          }
        } catch (error) {
          console.error('Error checking player status:', error);
          // If there's an error, safer to redirect to waiver
          navigate('/waiver');
        }
      }
    };

    checkPlayerStatus();
  }, [navigate]);

  if (isLoading) return <CenteredLoading />;
  
  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedTournament) {
      navigate(`/select-group-round/${selectedTournament}`);
    } else {
      console.error('Please select a tournament');
    }
  };

  const handleChange = (event) => {
    const tournamentId = event.target.value;
    const tournament = liveScores?.find(t => t.tournament_id === tournamentId);
    
    // Don't allow selection if tournament is finished
    if (tournament && !isTournamentFinished(tournament)) {
      setSelectedTournament(tournamentId);
    }
  };

  const isTournamentFinished = (tournament) => {
    return tournament?.events?.some(event => 
      event.event_status === "finished"
    ) || false;
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Select Tournament
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="tournament-select-label">Select a tournament</InputLabel>
            <Select
              labelId="tournament-select-label"
              id="tournament-select"
              value={selectedTournament}
              label="Select a tournament"
              onChange={handleChange}
            >
              {liveScores?.map((tournament) => {
                const isFinished = isTournamentFinished(tournament);
                return (
                  <MenuItem 
                    key={tournament.tournament_id} 
                    value={tournament.tournament_id}
                    disabled={isFinished}
                    sx={{
                      opacity: isFinished ? 0.6 : 1,
                      '&::after': isFinished ? {
                        content: '" (Finished)"',
                        marginLeft: 1,
                        fontSize: '0.85em',
                        color: 'text.disabled'
                      } : {}
                    }}
                  >
                    {tournament.tournament_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!selectedTournament}
          >
            Proceed to Event Selection
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default TournamentSelectionPage;