import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTournamentLiveScoresData } from '../hooks/useTournamentLiveScoresData';
import { useGroupRoundSelection } from '../hooks/useGroupRoundSelection';
import { Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import CenteredLoading from '../components/CenteredLoading';

const GroupRoundSelectionPage = () => {
  const { tournamentId } = useParams();
  const navigate = useNavigate();
  const { data: tournamentData, isLoading, error, refetch } = useTournamentLiveScoresData(tournamentId);
  const { selectedGroup, selectedRound, setGroup, setRound } = useGroupRoundSelection();

  useEffect(() => {
    if (error) {
      console.error('Error fetching tournament data:', error);
      // If there's an authentication error, redirect to login
      if (error.message.includes('authentication') || error.message.includes('login')) {
        navigate('/login');
      }
    }
  }, [error, navigate]);

  if (isLoading) return <CenteredLoading />;
  
  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedGroup && selectedRound) {
      navigate(`/scoring/${tournamentId}/${selectedRound}/${selectedGroup}`);
    }
  };

  const isSubmitDisabled = !selectedRound || !selectedGroup;

  const selectedEvent = tournamentData?.events.find(e => e.event_id === selectedRound);
  const groups = selectedEvent ? selectedEvent.teams : [];

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Select Round and Group
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Round</InputLabel>
            <Select
              value={selectedRound || ''}
              onChange={(e) => {
                setRound(e.target.value);
                setGroup(''); // Reset group when round changes
              }}
            >
              {tournamentData?.events.map((event) => (
                <MenuItem key={event.event_id} value={event.event_id}>
                  {event.event_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Group</InputLabel>
            <Select
              value={selectedGroup || ''}
              onChange={(e) => setGroup(e.target.value)}
              disabled={!selectedRound}
            >
              {groups.map((team) => (
                <MenuItem key={team.team_id} value={team.team_id}>
                  {team.team_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitDisabled}
          >
            Proceed to Scoring
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default GroupRoundSelectionPage;