import React, { useState } from 'react';
import { resetPassword } from '../services/apiService';
import { TextField, Button, Box, Typography, Container } from '@mui/material';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const result = await resetPassword(email, newPassword);
      setSuccess(result.message);  // Display success message from the backend
    } catch (err) {
      setError(err.message);  // Display error message from the backend
      console.error('Error resetting password:', err);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        p={3} 
        borderRadius={2} 
        boxShadow={3}
        bgcolor="background.paper"
      >
        <Typography variant="h5" gutterBottom>
          Reset Your Password
        </Typography>

        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <TextField
            label="New Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />

          <Button 
            variant="contained" 
            color="primary" 
            type="submit" 
            fullWidth
            sx={{ mt: 2 }}
          >
            Reset Password
          </Button>

          {success && (
            <Typography color="success.main" variant="body2" sx={{ mt: 2 }}>
              {success}
            </Typography>
          )}

          {error && (
            <Typography color="error.main" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default ResetPassword;
