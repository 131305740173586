import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPlayers, fetchTeams, fetchTournaments, fetchReferees, fetchLeagues, fetchSports, fetchSeasons, fetchPlayersByTeamId } from '../services/apiService';
import {
  TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Modal, Box, Typography, Button, IconButton, SearchIcon, useMediaQuery, Tabs, Tab, Grid, Tooltip
} from '@mui/material';
import { Add as AddIcon, People as PeopleIcon, Sports as SportsIcon, Group as GroupIcon, Clear as ClearIcon, ArrowUpward, ArrowDownward, SportsSoccer as SportsSoccerIcon, Edit as EditIcon} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const PlayerAdminPage = () => {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [referees, setReferees] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [sports, setSports] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  

  useEffect(() => {
    const loadData = async () => {
      try {
        const [
          fetchedPlayers,
          fetchedTeams,
          fetchedTournaments,
          fetchedReferees,
          fetchedLeagues,
          fetchedSports,
          fetchedSeasons,
        ] = await Promise.all([
          fetchPlayers(),
          fetchTeams(),
          fetchTournaments(),
          fetchReferees(),
          fetchLeagues(),
          fetchSports(),
          fetchSeasons(),
        ]);
  
        // Map TeamId -> TeamName
        const teamIdToNameMap = fetchedTeams.reduce((map, team) => {
          map[team.TeamId] = team.TeamName;
          return map;
        }, {});
  
        // Map LeagueId -> LeagueName
        const leagueIdToNameMap = fetchedLeagues.reduce((map, league) => {
          map[league.LeagueId] = league.LeagueName;
          return map;
        }, {});
  
        // Map SeasonId -> LeagueId
        const seasonToLeagueMap = fetchedSeasons.reduce((map, season) => {
          map[season.SeasonId] = season.LeagueId;
          return map;
        }, {});
  
        // Map SeasonId -> Season details
        const seasonIdToDetailsMap = fetchedSeasons.reduce((map, season) => {
          map[season.SeasonId] = {
            SeasonName: season.SeasonName,
            StartDate: season.StartDate,
            EndDate: season.EndDate,
          };
          return map;
        }, {});
  
        // Map LeagueId -> SportId
        const leagueToSportMap = fetchedLeagues.reduce((map, league) => {
          map[league.LeagueId] = league.SportId;
          return map;
        }, {});
  
        // Map SportId -> SportName
        const sportIdToNameMap = fetchedSports.reduce((map, sport) => {
          map[sport.SportId] = sport.SportName;
          return map;
        }, {});
  
        // Add TeamName, LeagueName, SportName, and Season to players
        const playersWithDetails = fetchedPlayers.map((player) => {
          const teamName = teamIdToNameMap[player.TeamId] || 'Unknown';
          const leagueId = player.LeagueId || seasonToLeagueMap[player.SeasonId];
          const leagueName = leagueIdToNameMap[leagueId] || 'Unknown';
          const sportId = leagueToSportMap[leagueId];
          const sportName = sportIdToNameMap[sportId] || 'Unknown';
          const season = seasonIdToDetailsMap[player.SeasonId] || {};
  
          return {
            ...player,
            TeamName: teamName,
            LeagueName: leagueName,
            SportName: sportName,
            SeasonName: season.SeasonName || 'Unknown',
            SeasonStartDate: season.StartDate || null,
            SeasonEndDate: season.EndDate || null,
          };
        });
  
        // Add LeagueName, SportName, and Season to teams
        const teamsWithDetails = fetchedTeams.map((team) => {
          const leagueId = seasonToLeagueMap[team.SeasonId];
          const leagueName = leagueIdToNameMap[leagueId] || 'Unknown';
          const sportId = leagueToSportMap[leagueId];
          const sportName = sportIdToNameMap[sportId] || 'Unknown';
          const season = seasonIdToDetailsMap[team.SeasonId] || {};
  
          return {
            ...team,
            LeagueName: leagueName,
            SportName: sportName,
            SeasonName: season.SeasonName || 'Unknown',
            SeasonStartDate: season.StartDate || null,
            SeasonEndDate: season.EndDate || null,
          };
        });
  
        setPlayers(playersWithDetails);
        setTeams(teamsWithDetails);
        setTournaments(fetchedTournaments);
        setReferees(fetchedReferees);
        setLeagues(fetchedLeagues);
        setSports(fetchedSports);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };
  
    loadData();
  }, []);
  
  
  const handleAddTournament = () => {
    navigate('/add-tournament'); // Replace with your route for adding tournaments
  };


  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleClearSearch = () => setSearchQuery(''); 
  const handleSortOrderChange = () => setSortOrder(prevSortOrder => prevSortOrder === 'asc' ? 'desc' : 'asc');

  const filteredPlayers = players
    .filter(player =>
      [player.FirstName, player.LastName, player.Email, player.SportName].some(field =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
    .sort((a, b) => sortOrder === 'asc' 
      ? new Date(a.createdAt) - new Date(b.createdAt)
      : new Date(b.createdAt) - new Date(a.createdAt)
    );

  const filteredTeams = teams
    .filter(team =>
      team.TeamName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => sortOrder === 'asc'
      ? new Date(a.createdAt) - new Date(b.createdAt)
      : new Date(b.createdAt) - new Date(a.createdAt)
    );

  const handleRowClick = (player) => {
    setSelectedPlayer(player);
    setOpenModal(true);
  };

  // Add state to manage the selected team's players
const [teamPlayers, setTeamPlayers] = useState([]);
const [selectedTeam, setSelectedTeam] = useState(null);
  
  // Add this function to handle team row clicks
const handleTeamRowClick = async (team) => {
  try {
    setSelectedTeam(team);
    const players = await fetchPlayersByTeamId(team.TeamId); // Fetch players for the clicked team
    setTeamPlayers(players);
  } catch (error) {
    console.error("Error loading team players:", error);
  }
};

  const handleCloseModal = () => setOpenModal(false);
  const handleCreatePlayer = () => navigate('/create-player');
  const handleTabChange = (event, newTab) => setSelectedTab(newTab);

  

   return (
    <div style={{ padding: '10px', maxWidth: '100%', margin: '0 auto' }}>
      <Typography variant="h4" align="center" sx={{ color: '#3f51b5', marginBottom: 2 }}>
        Admin Dashboard
      </Typography>

      <Tabs value={selectedTab} onChange={handleTabChange} centered variant="fullWidth">
        <Tab icon={<PeopleIcon />} label="Players" />
        <Tab icon={<GroupIcon />} label="Teams" />
        <Tab icon={<SportsSoccerIcon />} label="Tournaments" />
        <Tab icon={<SportsIcon />} label="Referees" />
      </Tabs>

      {selectedTab === 0 && (
        <>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Search Players"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                size="small"
                sx={{ backgroundColor: '#f1f1f1', borderRadius: 1 }}
                InputProps={{
                  endAdornment: searchQuery && (
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={4} sx={{ textAlign: 'right' }}>
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreatePlayer}>
                Add New Player
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper} sx={{ maxHeight: 600, mt: 2 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Team</TableCell> {/* New column for Team Name */}
                  <TableCell>Sport</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell onClick={handleSortOrderChange}>
                    Created At {sortOrder === 'asc' ? <ArrowDownward /> : <ArrowUpward />}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPlayers.map((player) => (
                  <TableRow key={player.PlayerId} hover onClick={() => handleRowClick(player)}>
                    <TableCell>{player.FirstName}</TableCell>
                    <TableCell>{player.LastName}</TableCell>
                    <TableCell>{player.Email}</TableCell>
                    <TableCell>{player.TeamName}</TableCell> {/* Show Team Name */}
                    <TableCell>{player.SportName}</TableCell>
                    <TableCell>{player.Active ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{new Date(player.createdAt).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>




<Modal open={openModal} onClose={handleCloseModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 1,
    }}
  >
    {selectedPlayer && (
      <>
        <Typography variant="h6" gutterBottom>
          {selectedPlayer.FirstName} {selectedPlayer.LastName}
        </Typography>
        <Typography variant="body1"><strong>Email:</strong> {selectedPlayer.Email}</Typography>
        <Typography variant="body1"><strong>Phone:</strong> {selectedPlayer.PhoneNumber}</Typography>
        <Typography variant="body1"><strong>Date of Birth:</strong> {selectedPlayer.DOB}</Typography>
        <Typography variant="body1"><strong>Position:</strong> {selectedPlayer.Position}</Typography>
        <Typography variant="body1"><strong>Age:</strong> {selectedPlayer.Age}</Typography>
        <Typography variant="body1"><strong>Active:</strong> {selectedPlayer.Active ? 'Yes' : 'No'}</Typography>
        <Typography variant="body1"><strong>Is Playing:</strong> {selectedPlayer.IsPlaying ? 'Yes' : 'No'}</Typography>
        {/* <Typography variant="body1"><strong>League ID:</strong> {selectedPlayer.LeagueId}</Typography>
        <Typography variant="body1"><strong>Team ID:</strong> {selectedPlayer.TeamId}</Typography>
        <Typography variant="body1"><strong>Coach ID:</strong> {selectedPlayer.CoachId}</Typography> */}

        {/* URL Buttons */}
        {selectedPlayer.DrivingLicenseURL && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(selectedPlayer.DrivingLicenseURL, '_blank')}
            sx={{ mt: 1 }}
          >
            View Driving License
          </Button>
        )}
        {selectedPlayer.HeadshotURL && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(selectedPlayer.HeadshotURL, '_blank')}
            sx={{ mt: 1 }}
          >
            View Headshot
          </Button>
        )}
        {selectedPlayer.SignedDocumentURL && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => window.open(selectedPlayer.SignedDocumentURL, '_blank')}
            sx={{ mt: 1 }}
          >
            View Signed Document
          </Button>
        )}

        <Button
          variant="contained"
          color="success"
          sx={{ mt: 2 }}
          onClick={() => navigate(`/players/edit/${selectedPlayer.PlayerId}`)}
        >
          Edit
        </Button>

        <Button onClick={handleCloseModal} sx={{ mt: 2, ml: 1 }} variant="contained" color="primary">
          Close
        </Button>
      </>
    )}
  </Box>
</Modal>;

</>
      )}

{selectedTab === 1 && (
  <>
    <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
    <Grid item xs={12} md={4}>  
        <TextField
          label="Search Teams"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          size="small"
          sx={{ backgroundColor: '#f1f1f1', borderRadius: 1 }}
          InputProps={{
            endAdornment: searchQuery && (
              <IconButton onClick={handleClearSearch}>
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </Grid>

      {/* Add Team Button */}
      <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          href="/add-team" // Link to the Add Team page
          sx={{
            textTransform: 'none',
            backgroundColor: '#3f51b5',
            ':hover': { backgroundColor: '#303f9f' },
          }}
        >
          Add Team
        </Button>
      </Grid>
    </Grid>

    {/* Teams Table */}
    <TableContainer component={Paper} sx={{ maxHeight: 600, mt: 2 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Team Name</TableCell>
                  <TableCell>League</TableCell>
                  <TableCell>Sport</TableCell>
                  <TableCell>Season</TableCell>
                  {/* <TableCell>Active</TableCell> */}
                  {/* <TableCell>
                    <Tooltip title="Sort Order">
                      <IconButton onClick={handleSortOrderChange}>
                        {sortOrder === 'asc' ? <ArrowDownward /> : <ArrowUpward />}
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTeams.map((team) => (
                  <TableRow
                    key={team.TeamId}
                    hover
                    onClick={() => handleTeamRowClick(team)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{team.TeamName}</TableCell>
                    <TableCell>{team.LeagueName}</TableCell>
                    <TableCell>{team.SportName}</TableCell>
                    <TableCell>{team.SeasonName}</TableCell>
                    {/* <TableCell>{team.Active ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleTeamRowClick(team)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

    {/* Modal for Team Players */}
    <Modal open={!!selectedTeam} onClose={() => setSelectedTeam(null)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxHeight: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Players for Team: {selectedTeam?.TeamName}
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamPlayers.map((player) => (
                <TableRow key={player.PlayerId}>
                  <TableCell>{player.FirstName}</TableCell>
                  <TableCell>{player.LastName}</TableCell>
                  <TableCell>{player.Email}</TableCell>
                  <TableCell>{player.Position}</TableCell>
                  <TableCell>{player.Active ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedTeam(null)}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  </>
)}


      {selectedTab === 2 && (
         <>
         <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
           <Grid item xs={12} md={8}>
             {/* <Typography variant="h6" gutterBottom>
               Manage Tournaments
             </Typography> */}
           </Grid>
           <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
             <Button
               variant="contained"
               startIcon={<AddIcon />}
               onClick={handleAddTournament}
               sx={{
                 backgroundColor: '#4caf50',
                 color: 'white',
                 '&:hover': { backgroundColor: '#388e3c' },
                 width: isMobile ? '100%' : 'auto',
               }}
             >
               Add Tournament
             </Button>
           </Grid>
         </Grid>

        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Tournament Name</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Format</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Start Date</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tournaments.map((tournament) => (
                <TableRow key={tournament.TournamentId}>
                  <TableCell>{tournament.TournamentName}</TableCell>
                  <TableCell>{tournament.Format}</TableCell>
                  <TableCell>{tournament.StartDate}</TableCell>
                  <TableCell>{tournament.EndDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>
      )}

{selectedTab === 3 && (
  <Box>
    {/* Add Referee Button */}
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
      <Button
        variant="contained"
        color="primary"
        href="/add-Referee" // Link to the Add Team page
        sx={{
          textTransform: 'none',
          backgroundColor: '#3f51b5',
          ':hover': { backgroundColor: '#303f9f' },
        }}
      >
        Add Referee
      </Button>
    </Box>

    {/* Referee Table */}
        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>First Name</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Last Name</TableCell>   
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Experience</TableCell>
                <TableCell sx={{ color: '#3f51b5', fontWeight: 'bold' }}>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referees.map((referee) => (
                <TableRow key={referee.RefereeId}>
                  <TableCell>{referee.FirstName}</TableCell>
                  <TableCell>{referee.LastName}</TableCell>
                  <TableCell>{referee.Age} years</TableCell>
                  <TableCell>{referee.Email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      )}
    </div>
  );
};

export default PlayerAdminPage;
