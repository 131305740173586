import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Breadcrumb = ({ tournamentId, tournamentName, eventId, eventName, group }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link component={RouterLink} to="/select-tournament" color="inherit">
        Tournaments
      </Link>
      {tournamentId && tournamentName && (
        <Link component={RouterLink} to={`/select-group-round/${tournamentId}`} color="inherit">
          {tournamentName}
        </Link>
      )}
      {eventId && eventName && (
        <Link component={RouterLink} to={`/select-group-round/${tournamentId}/${eventId}`} color="inherit">
          {eventName}
        </Link>
      )}
      {group && <Typography color="text.primary">{group}</Typography>}
    </Breadcrumbs>
  );
};

export default Breadcrumb;