import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { updatePlayerProfile, getPlayerProfile, fetchTeams } from '../services/apiService';
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Alert,
  Snackbar,
  Switch,
  FormControlLabel,
  MenuItem,
} from '@mui/material';

const EditPlayer = () => {
  const { playerId } = useParams();
  const navigate = useNavigate();

  // Player state
  const [player, setPlayer] = useState({
    FirstName: '',
    LastName: '',
    DOB: '',
    Email: '',
    PhoneNumber: '',
    Position: '',
    Age: '',
    Active: true,
    TeamId: '', // Add TeamId to player state
  });

  const [teams, setTeams] = useState([]); // State for teams
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch player data and teams
  useEffect(() => {
    const fetchPlayerAndTeams = async () => {
      try {
        const [playerData, teamData] = await Promise.all([
          getPlayerProfile(playerId),
          fetchTeams(),
        ]);
        setPlayer(playerData);
        setTeams(teamData);
        setLoading(false);
      } catch (err) {
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };
    fetchPlayerAndTeams();
  }, [playerId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlayer((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleActive = (e) => {
    const { checked } = e.target;
    setPlayer((prev) => ({ ...prev, Active: checked }));
  };

  const handleUpdate = async () => {
    try {
      const updatedPlayer = await updatePlayerProfile(playerId, player);
      setSuccessMessage('Player updated successfully');
      navigate('/players');
    } catch (error) {
      setError('Failed to update player');
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto', mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Edit Player
      </Typography>
      <form onSubmit={(e) => e.preventDefault()}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="FirstName"
              value={player.FirstName}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="LastName"
              value={player.LastName}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="DOB"
              type="date"
              name="DOB"
              value={player.DOB}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              type="email"
              name="Email"
              value={player.Email}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              name="PhoneNumber"
              value={player.PhoneNumber}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Age"
              name="Age"
              type="number"
              value={player.Age}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Position"
              name="Position"
              value={player.Position}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Team"
              select
              name="TeamId"
              value={player.TeamId}
              onChange={handleChange}
              fullWidth
              required
            >
              {teams.map((team) => (
                <MenuItem key={team.TeamId} value={team.TeamId}>
                  {team.TeamName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={player.Active}
                  onChange={handleToggleActive}
                  color="primary"
                />
              }
              label={player.Active ? 'Active' : 'Inactive'}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              fullWidth
              sx={{ mt: 2 }}
            >
              Update Player
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/players')}
              fullWidth
              sx={{ mt: 1 }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
    </Box>
  );
};

export default EditPlayer;
