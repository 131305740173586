import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert, AlertTitle, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useTournamentLiveScoresData } from '../hooks/useTournamentLiveScoresData';
// import { useGroupRoundSelection } from '../hooks/useGroupRoundSelection';
import { useScoring } from '../hooks/useScoring';
import HoleSelectionGrid from '../components/HoleSelectionGrid';
import PlayerScoringCard from '../components/PlayerScoringCard';
import CenteredLoading from '../components/CenteredLoading';
import Breadcrumb from '../components/Breadcrumb';
import { refreshToken, isAuthenticated } from '../services/apiService';

const ScoringPage = () => {
  const { tournamentId, eventId, teamId } = useParams();
  const navigate = useNavigate();
  const { data: tournamentData, isLoading, error: tournamentError } = useTournamentLiveScoresData(tournamentId);
  const [selectedHole, setSelectedHole] = useState(null);
  const [isBackDialogOpen, setIsBackDialogOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [persistentError, setPersistentError] = useState(null);
  const [isPlayoffGroup, setIsPlayoffGroup] = useState(false);
  const [displayRound, setDisplayRound] = useState('');

  const { 
    updateScore, 
    getPlayerScore, 
    declareWinner, 
    isUpdating,
    isPending,
    isPaused 
  } = useScoring(tournamentId, eventId, isPlayoffGroup);

  const removeNotification = useCallback((id) => {
    setNotifications(prev => prev.filter(n => n.id !== id));
  }, []);

  const addNotification = useCallback((message, severity, duration = 5000) => {
    const id = Date.now();
    setNotifications(prev => [{ id, message, severity }, ...prev].slice(0, 3));
    
    if (duration > 0) {
      setTimeout(() => removeNotification(id), duration);
    }
  }, [removeNotification]);

  useEffect(() => {
    if (tournamentData) {
      const event = tournamentData.events.find(e => e.event_id === eventId);
      if (event) {
        const isPlayoff = event.sport_details?.playoff_occured === true &&
                         !!event.sport_details?.playoff_scoring;
        setIsPlayoffGroup(isPlayoff);
      }
    }
  }, [tournamentData, eventId]);

  const handleScoreUpdate = async (player, holeNumber, score) => {
    const pendingId = Date.now();
    addNotification(`Updating score for ${player.player_name}...`, 'info', 0);
    
    try {
      const result = await updateScore(player, holeNumber, score);
      // console.log('Score update result:', result);
      removeNotification(pendingId);
      
      if (result.type === 'success') {
        addNotification(result.message, 'success');
      } else if (result.type === 'error') {
        addNotification(result.message, 'error');
      } else if (result.type === 'info') {
        addNotification(result.message, 'info');
      } else if (result.type === 'offline') {
        addNotification(result.message, 'warning');
      }
    } catch (error) {
      removeNotification(pendingId);
      console.error('Error in handleScoreUpdate:', error);
      addNotification(`Error updating score for ${player.player_name}: ${error.message}`, 'error');
    }
  };

  useEffect(() => {
    if (isPaused) {
      setNotifications(prev => {
        const updatingNotifications = prev.filter(n => n.severity === 'info');
        return [
          ...updatingNotifications.map(n => ({...n, message: n.message.replace('Updating', 'Waiting to update'), severity: 'warning'})),
          ...prev.filter(n => n.severity !== 'info')
        ];
      });
      addNotification('Network interrupted. Updates will resume when connection is restored.', 'warning');
    } else if (!isPending) {
      setNotifications(prev => prev.filter(n => n.severity !== 'info' && n.severity !== 'warning'));
    }
  }, [isPaused, isPending, addNotification]);

  const handleBackToHoleSelection = () => {
    setSelectedHole(null);
  };

  const handleBackClick = () => {
    setIsBackDialogOpen(true);
  };

  const handleBackConfirm = () => {
    setIsBackDialogOpen(false);
    navigate(`/select-group-round/${tournamentId}`);
  };

  const handleBackCancel = () => {
    setIsBackDialogOpen(false);
  };

  useEffect(() => {
    if (tournamentData && eventId) {
      const event = tournamentData.events.find(e => e.event_id === eventId);
      if (event) {
        setDisplayRound(event.round_sequence.toString());
      }
    }
  }, [tournamentData, eventId]);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!document.hidden && isAuthenticated()) {
        try {
          await refreshToken();
          console.log('Token refreshed on visibility change');
        } catch (error) {
          console.error('Failed to refresh token on visibility change:', error);
          if (!localStorage.getItem('token')) {
            navigate('/login');
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [navigate]);

  if (isLoading) return <CenteredLoading />;
  if (tournamentError) return <Typography color="error">{tournamentError.message}</Typography>;
  if (!tournamentData) return <Typography>No tournament data available.</Typography>;

  const currentEvent = tournamentData.events.find(e => e.event_id === eventId);
  if (!currentEvent) return <Typography>Event not found.</Typography>;

  const isPlayoffScoring = !!currentEvent.sport_details.playoff_scoring;
  const scoringData = isPlayoffScoring
    ? currentEvent.sport_details.playoff_scoring
    : currentEvent.sport_details.regulation_scoring;

  if (!scoringData || !Array.isArray(scoringData) || scoringData.length === 0) {
    return <Typography>No scoring data available for this event.</Typography>;
  }

  const players = scoringData.filter(player => player.team_id === teamId);
  const holes = players.length > 0 ? players[0].holes : [];

  const groupName = currentEvent.teams.find(team => team.team_id === teamId)?.team_name || '';

  return (
    <Container maxWidth="lg">
      <Breadcrumb 
        tournamentId={tournamentId}
        tournamentName={tournamentData.tournament_name}
        eventId={eventId}
        eventName={currentEvent.event_name}
        group={groupName}
      />
      <Box sx={{ mt: 4, mb: 4, position: 'relative' }}>
        {!selectedHole && (
          <Button 
            startIcon={<ArrowBackIcon />}
            onClick={handleBackClick} 
            sx={{ position: 'relative', top: 0, left: 0, zIndex: 1 }}
            aria-label="Back to group and round selection"
          >
            Back
          </Button>
        )}
        <Typography align="center" variant="h5" component="h2" gutterBottom sx={{ pl: 4 }}>
          {isPlayoffGroup ? 'Playoff Scoring' : 'Regulation Scoring'} - Round {displayRound || 'Loading...'}
        </Typography>
        {!selectedHole ? (
          <HoleSelectionGrid 
            holes={holes} 
            onHoleSelect={setSelectedHole}
          />
        ) : (
          <PlayerScoringCard 
            players={players}
            holeNumber={selectedHole}
            tournamentId={tournamentId}
            eventId={eventId}
            isPlayoffMode={isPlayoffGroup}
            onBack={handleBackToHoleSelection}
            onScoreUpdate={handleScoreUpdate}
            getPlayerScore={getPlayerScore}
            onHoleChange={setSelectedHole}
            totalHoles={holes.length}
            groupName={groupName}
            declareWinner={declareWinner}
            isUpdating={isUpdating}
            isPending={isPending}
            isPaused={isPaused}
          />
        )}
      </Box>
      <Dialog
        open={isBackDialogOpen}
        onClose={handleBackCancel}
      >
        <DialogTitle>Confirm Navigation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to go back to group and round selection?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBackCancel}>Cancel</Button>
          <Button onClick={handleBackConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ position: 'fixed', bottom: 16, left: 16, right: 16, zIndex: 1000 }}>
        {notifications.map(({ id, message, severity }) => (
          <Alert 
            key={id}
            severity={severity}
            sx={{ mb: 1 }}
            onClose={() => removeNotification(id)}
          >
            {message}
          </Alert>
        ))}
        {persistentError && (
          <Alert 
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setPersistentError(null)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>Error</AlertTitle>
            {persistentError}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default ScoringPage;