import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar as MuiToolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { logout } from '../services/apiService';

// Styled components for the toolbar and logo
const StyledToolbar = styled(MuiToolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
}));

const Logo = styled('img')({
  height: '50px',
});

const Toolbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if screen is small (mobile)
  const [drawerOpen, setDrawerOpen] = useState(false); // State for mobile drawer
  const [anchorEl, setAnchorEl] = useState(null); // State for profile menu
  const [role, setRole] = useState(null); // State to store user role
  const open = Boolean(anchorEl);

  // Get roleName from localStorage when component mounts
  useEffect(() => {
    const storedRole = localStorage.getItem('roleName');
    setRole(storedRole); // Set the role in state
  }, []);

  const handleLogout = async () => {
    try {
      await logout(); // Call to logout service
      localStorage.removeItem('roleName'); // Clear stored role data
      localStorage.removeItem('token'); // Clear stored token
      setRole(null); // Clear in-component role state
      navigate('/login', { replace: true }); // Ensure navigation overwrites current history
      window.location.reload(); // Refresh the page to reset state completely
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  
  // Function to handle profile menu toggle
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close profile menu
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Define the menu items
  const menuItems = [
    { text: 'Select Tournament', onClick: () => navigate('/select-tournament') },
    { text: 'Create Playoffs', onClick: () => navigate('/playoff-selection') },
    { text: 'Leaderboard', onClick: () => navigate('/leaderboard') },
    { text: 'Admin', onClick: () => navigate('/players') },
  ];

  // Filter menu items based on the user's role
  const filteredMenuItems = menuItems.filter((item) => {
    if (role === 'Player') {
      return item.text !== 'Select Tournament' && item.text !== 'Create Playoffs' && item.text !== 'Admin';
    }
    return true; // Show all items for non-player roles
  });

  // Render menu items for larger screens (non-mobile)
  const renderMenuItems = () => (
    <>
      {filteredMenuItems.map((item, index) => (
        <Button key={index} color="inherit" onClick={item.onClick} sx={{ mr: 2 }}>
          {item.text}
        </Button>
      ))}
    </>
  );

  return (
    <AppBar position="static" color="default" elevation={1}>
      <StyledToolbar>
        {/* Logo */}
        <Logo src={`${process.env.PUBLIC_URL}/pln_black_transparent_logo.png`} alt="PLN Logo" />

        {/* Display on larger screens */}
        {!isMobile && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Render navigation menu items */}
            {renderMenuItems()}
            
            {/* Profile icon and menu */}
            <IconButton onClick={handleMenu} color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
              <MenuItem onClick={() => navigate('/admin')}>Profile</MenuItem>
              <MenuItem onClick={() => navigate('/change-password')}>Change Password</MenuItem> {/* Add Change Password */}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}

        {/* Display on mobile screens */}
        {isMobile && (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <List>
                {/* Render menu items in the mobile drawer */}
                {filteredMenuItems.map((item, index) => (
                  <ListItem
                    key={index}
                    onClick={() => {
                      item.onClick();
                      setDrawerOpen(false); // Close the drawer after navigation
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
                {/* Add Change Password and Logout in the drawer */}
                <ListItem onClick={() => navigate('/change-password')}>
                  <ListItemText primary="Change Password" />
                </ListItem>
                <ListItem onClick={handleLogout}>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Drawer>
          </>
        )}
      </StyledToolbar>
    </AppBar>
  );
};

export default Toolbar;
