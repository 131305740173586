export const waiverText = {
    header: `EVENT AGREEMENT`,

    introduction: `This agreement is made between the undersigned Athlete and Pro League Network Inc.

    Athlete agrees to participate in Str33t to be held at 1157 Lemoyne St, Los Angeles, CA 90026, City of Los Angeles, State of California ("Event")

Event      
Str33t series
Compensation:$500 per team`,

formFields: `Athlete Name: 
Signature:
Date: 
Legal Name (as it appears on ID): 
Home Address: 
Date of Birth:  
ID Type: 
Identification Number #: 
State or Country: 
Expiration Date:   
Telephone Number: 
Email Address: `,

  taxCertification: `Under penalties of perjury, I certify that: 1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me), and 2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or c) the IRS has notified me that I am no longer subject to backup withholding, and 3. I am a U.S. citizen or other U.S. person (defined later in general instructions).`,

  codeOfConduct: `ATHLETE CODE OF CONDUCT

General Policy:
PLN is dedicated to maintaining a league culture of mutual respect and safety for all Athletes, Officials, and Staff. 

Athletes are expected to act in accordance with commonly accepted standards of decency, social conventions and morals. Athlete will not commit any act or become involved in any situation or occurrence or make any statement which will reflect negatively upon or bring disrepute, contempt, scandal, ridicule, or disdain to Athlete, the Identity of Athlete or any of Athlete's Affiliates, PLN or any of its officers, managers, members, employees, or agents. Conduct includes in-person and online or digital interactions for the term of this agreement. 

Athlete's conduct shall not be such as to shock, insult or offend the public or any organized group therein, or reflect unfavorably upon any current or proposed arena, venue, site hotel, sponsor or such sponsor's advertising agency, or any network or station over which an Event is  to  be broadcast. In  addition,  Athlete  agrees that during  a Event, or while training for an Event, as well as during any Pre-Event Events or Post-Event Events, neither Athlete nor any of his managers, trainers and assistants shall wear any clothing or ornamentation, including, but not limited to,  permanent  or  temporary  tattoos  or  body  art or modifications   that   incorporates   lewd,  obscene,  offensive,  defamatory, discriminatory on the basis of age, sex, race, color, creed, national origin, political  belief, religious belief or sexual orientation  or otherwise inappropriate as determined by   PLN in its sole discretion or which conflicts with a sponsor, the arena or telecaster. 

Athletes shall maintain a high standard of sportsmanship and engage in a professional manner prior to, during, and following each Event.

Athlete  shall  not authorize  or  be  involved  with  any advertising  material  or publicity materials  that  contain  language  or  material  which  is  generally  considered  to  be  obscene,  libelous, slanderous  or defamatory  and will  not violate  or  infringe  upon, or give rise to any adverse  claim with respect to,  any common-law  or other  right  whatsoever (including,  but  not  limited  to,  any copyright, trademark, service mark, literary,  dramatic, music or motion picture  right,  right of privacy or publicity, contract or moral rights of authors} of any person or entity.`,

  personalConduct: `Personal Conduct Policy:
All persons associated with the PLN are required to avoid "conduct detrimental to the integrity of and public confidence in the sport. 
A. Standard of Conduct:Discipline may be imposed for any action deemed to be conduct detrimental including but not limited to  any of the following circumstances:
1) Criminal offenses including, but not limited to, those involving: the use or threat of violence; domestic violence and other forms of partner abuse; theft and other property crimes; sex offenses; obstruction or resisting arrest; disorderly conduct; fraud; racketeering; and money laundering.
2) Criminal offenses relating to steroids and prohibited substances, or substances of abuse.
3) Violent or threatening behavior among fellow players, league or team staff, whether in or outside the workplace.
4) Possession of a gun or other weapon in any workplace setting, including but not limited to Competition venues, locker rooms, planes, buses, parking lots, etc., or unlawful possession of a weapon outside of the workplace.
5) Conduct that imposes inherent danger to the safety and wellbeing of another person; and
6) Conduct that undermines or puts at risk the integrity and reputation of the sport or its Athletes.
B. Discipline:Upon learning of conduct that may give rise to discipline, the PLN will direct an investigation which may include interviews and information gathering from medical, law enforcement, and other relevant professionals.  PLN, or its agents, will promptly advise the athlete of any investigation, as well as of the results of the investigation. All decisions by PLN shall be final and unappealable. 
Discipline may take the form of fines, suspension, or banishment from the PLN, termination of this agreement, and may include a probationary period and conditions that must be satisfied prior to or following reinstatement. The specifics of the disciplinary response will be based on the nature of the incident, the actual or threatened risk to the participant and others, any prior or additional misconduct (whether or not criminal charges were filed), and other relevant factors.`,

  medicalSafety: `C. Medical Treatment and Safety:
PLN endeavors to conduct league operations and Events as safely as possible, and to maintain a culture of safety. If injured while competing or in an emergency, Athlete consents to representatives of PLN to have the authority, at Athlete's expense, to render aid and medical attention and to utilize the most convenient volunteer rescue squad vehicle or ambulance to the hospital and authorize medical treatment.    
Athlete must immediately report to PLN if injured in the performance of services under this Agreement. Athlete agrees to make full and complete disclosure of any physical or mental condition and/or known history known which might impair performance under this Agreement and to respond fully and in good faith when questioned by the PLN physician about any such condition. Athlete agrees to abstain from PLN activities if conditions that may impair performance are incurred. PLN reserves the right to require the Athlete to submit to full or partial physical medical examination and undergo testing and receive affirmative clearance prior to each Event.`,

  antiGambling: `D. Anti-Gambling Policy:
This policy covers the following groups of individuals:

	•	"Personnel'' means any official, rule judge, scorer, employee (whether full-time, part-time or contractor) who provides services as part of their direct or indirect contracted duties to an Event by Pro League Network Inc.
	•	"Players" means any person who enters into a contract to compete as a player in an Event.
	•	For the purposes of clarity, this policy also covers all facets of 
competition including the outcome of the tournament, outcome of a board, and any
head-to-head match, individual player score, and the individual or aggregate performance 

  PLN Personnel and Players shall not do any of the following:

	•	Fail to give their best efforts in competition. Players and
personnel are prohibited from influencing or manipulating (or attempting to influence or
manipulate) any competition so that the final outcome or any other outcome or aspect of the game or event is fully determined by anything other than its merits.
	•	Associate with or have dealings with persons whose activities, including gambling, might reflect adversely upon the integrity of the game.
	•	Bet money or anything of value on an Event, whether or not the player is a competitor in such a competition.
	•	Bet money or other tangible goods on any Event. Players or personnel may not ask others to place bets on their behalf, or otherwise benefit financially from, or assist with, bets placed by others.
	•	Participate in, ask others to participate on their behalf, assist any third party with or
otherwise benefiting financially from any related fantasy games (e.g., daily fantasy, full-season fantasy, or fantasy props) in which prize money or other things of value are available to participants.
	•	Place illegal bets on any Event, including bets placed with illegal bookmakers or illegal off-shore sports betting websites or applications.
	•	Offer or give a bribe, gift, payment, reward or anything of value to any player with the
intention of influencing his efforts in a competition.
	•	Solicit, agree to accept or accept a bribe, gift, payment, reward or anything of value
offered or given with the intention of influencing the player's efforts in a competition. 
	•	Share any information about a player, competition or anything else related to an Event that is not publicly available and could be used or considered as
"confidential," "nonpublic," or "inside" information that could be used to provide an unfair advantage.  
	•	Fail to report promptly any known or suspected offer, bribe, gift, payment, reward or
anything of value, or any agreement or acceptance or bet as described in these
provisions. A player must disclose if they are contacted by any individual about gambling and betting relating to themselves or others about this specific event.

Any player who violates these provisions shall be subject to a suspension from
tournament play for a minimum period of two (2) years. 

Any Personnel who violate these provisions are subject to immediate termination.`,

  antiDoping: `E. Anti-Doping Policy:
The use of doping substances or doping methods to enhance performance is fundamentally wrong and is detrimental to the overall spirit of sport. Drug misuse may be harmful to an athlete's health and to other athletes competing in the sport. It severely damages the integrity, image and value of sport, whether or not the use of drugs is to improve performance. To achieve integrity and fairness, a commitment to a clean sport is critical.

It is each athlete's personal duty to ensure that no Prohibited Substance enters his or her body. Athletes are responsible for any Prohibited Substance found to be present in their Samples. 

Prohibited Substances: S1 Anabolic agents S2 Peptide hormones, growth factors, related substances, and S3 Beta-2 agonists, S4 Hormone and metabolic, S5 Diuretics and masking, S6 Stimulants, S7 Narcotics, S8 Cannabinoids, S9 Glucocorticoids, P1 Beta-blockers

Doping is defined as the occurrence of one or more of the following Anti-Doping Rule Violations (ADRVs):
	•	Presence of a prohibited substance in an athlete's sample
	•	Use or attempted use of a prohibited substance or method 
	•	Refusing to submit to sample collection after being notified 
	•	Failure to file athlete whereabouts information and missed tests
	•	Tampering with any part of the doping control process
	•	Possession of a prohibited substance or method
	•	Trafficking a prohibited substance or method
	•	Administering or attempting to administer a prohibited substance or method to an athlete
	•	Prohibited association with athlete support personnel who has engaged in doping
Any player who violates these provisions shall be subject to a suspension from
tournament play for a minimum period of two (2) years.`
};