import React, { createContext, useReducer, useContext, useCallback } from 'react';

const AppContext = createContext();

const initialState = {
  user: null,
  loading: false,
  error: null
};

function appReducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
}

export function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

export function useAuth() {
  const { state, dispatch } = useAppContext();
  const setUser = useCallback((user) => {
    dispatch({ type: 'SET_USER', payload: user });
  }, [dispatch]);

  return {
    user: state.user,
    setUser,
    isAuthenticated: !!state.user
  };
}

export function useLoadingError() {
  const { state, dispatch } = useAppContext();
  const setLoading = useCallback((isLoading) => {
    dispatch({ type: 'SET_LOADING', payload: isLoading });
  }, [dispatch]);
  const setError = useCallback((error) => {
    dispatch({ type: 'SET_ERROR', payload: error });
  }, [dispatch]);

  return {
    loading: state.loading,
    error: state.error,
    setLoading,
    setError
  };
}
