import React, { useState, useEffect } from 'react';
import { Container, Button, Typography, CircularProgress } from '@mui/material';
import { getPlayerProfile } from '../services/apiService';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

const DrivingLicenseUploadPage = () => {
  const [drivingLicenseImage, setDrivingLicenseImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [existingLicenseUrl, setExistingLicenseUrl] = useState(null);
  const [extractedData, setExtractedData] = useState({ 
    fullName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    dob: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPlayerProfile = async () => {
      const playerId = localStorage.getItem('playerId');
      if (playerId) {
        try {
          const profile = await getPlayerProfile(playerId);
          setExistingLicenseUrl(profile.DrivingLicenseURL);
        } catch (error) {
          console.error("Error fetching player profile:", error);
          setUploadMessage('Failed to fetch player profile.');
        }
      }
    };
    fetchPlayerProfile();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setDrivingLicenseImage(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
    }
  };

  const handleUpload = async () => {
    const playerId = localStorage.getItem('playerId');
    if (!playerId) {
      setUploadMessage('No player ID found in localStorage.');
      return;
    }
    if (!drivingLicenseImage) {
      setUploadMessage("No file selected for upload.");
      return;
    }
    setLoading(true);
    try {
      const azureResponse = await fetch(
        "https://driving-lic.cognitiveservices.azure.com/formrecognizer/documentModels/prebuilt-layout:analyze?api-version=2023-07-31",
        {
          method: "POST",
          headers: {
            "Ocp-Apim-Subscription-Key": "2d80b1234fe64a25af0abd0863f5650d",
            "Content-Type": "application/octet-stream"
          },
          body: drivingLicenseImage
        }
      );
      if (!azureResponse.ok) {
        const errorText = await azureResponse.text();
        throw new Error(`Failed to extract data from Azure: ${errorText}`);
      }
      const operationLocation = azureResponse.headers.get('operation-location');
      if (!operationLocation) throw new Error('Operation location not found in response.');
      const resultData = await pollForResults(operationLocation);
      processResultData(resultData);
      setUploadMessage("Driving license details extracted successfully.");
      setImagePreviewUrl(URL.createObjectURL(drivingLicenseImage));
    } catch (error) {
      setUploadMessage(`Error: ${error.message}`);
      setError('Error during upload. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const pollForResults = async (operationLocation) => {
    let status = 'running';
    while (status === 'running') {
      await new Promise(resolve => setTimeout(resolve, 5000));
      const resultResponse = await fetch(operationLocation, {
        headers: {
          "Ocp-Apim-Subscription-Key": "2d80b1234fe64a25af0abd0863f5650d"
        }
      });
      if (!resultResponse.ok) {
        const errorText = await resultResponse.text();
        throw new Error(`Failed to retrieve results: ${errorText}`);
      }
      const resultData = await resultResponse.json();
      status = resultData.status;
      if (status !== 'running') return resultData;
    }
  };

  const processResultData = (resultData) => {
    const newExtractedData = {
      fullName: '',
      streetAddress: '',
      city: '',
      state: '',
      zipCode: '',
      dob: ''
    };

    let nameLines = [];
    let hasFoundName = false;

    resultData.analyzeResult.pages.forEach((page) => {
      page.lines.forEach((line) => {
        const content = line.content.trim();
        console.log(`Processing line: ${content}`);

        // Enhanced name extraction
        if (!hasFoundName) {
          // Common name patterns
          const namePatterns = [
            /^([A-Z][a-z]+\s+[A-Z][a-z]+(?:\s+[A-Z][a-z]+)?)$/,  // Standard name format (First Middle? Last)
            /^([A-Z]+,\s*[A-Z]+(?:\s+[A-Z]+)?)$/,  // Last, First Middle?
            /^(?:NAME|LN|FN|DLN):\s*([A-Z][a-z]+\s+[A-Z][a-z]+(?:\s+[A-Z][a-z]+)?)$/i,  // Labeled name
            /^([A-Z][A-Z\s]+)$/  // All caps name
          ];

          for (const pattern of namePatterns) {
            const match = content.match(pattern);
            if (match) {
              const extractedName = match[1];
              // Format name properly (convert from "LAST, FIRST" to "First Last" if needed)
              if (extractedName.includes(',')) {
                const [last, first] = extractedName.split(',').map(part => part.trim());
                newExtractedData.fullName = `${first} ${last}`;
              } else {
                newExtractedData.fullName = extractedName;
              }
              hasFoundName = true;
              break;
            }
          }

          // Store potential name lines for fallback processing
          if (content.match(/^[A-Z][a-zA-Z\s,]+$/)) {
            nameLines.push(content);
          }
        }

        // Address extraction
        if (content.match(/^\d+\s/)) {
          newExtractedData.streetAddress = content;
        }
        const cityStateZipMatch = content.match(/([A-Z\s]+),\s*([A-Z]{2})\s*(\d{5}(?:-\d{4})?)/);
        if (cityStateZipMatch) {
          newExtractedData.city = cityStateZipMatch[1].trim();
          newExtractedData.state = cityStateZipMatch[2];
          newExtractedData.zipCode = cityStateZipMatch[3];
        }

        // DOB extraction
        const dobMatches = [
          /DOB[:\s]*(\d{2}\/\d{2}\/\d{4})/i,
          /BIRTH\s*DATE[:\s]*(\d{2}\/\d{2}\/\d{4})/i,
          /(\d{2}\/\d{2}\/\d{4})/
        ];

        for (const pattern of dobMatches) {
          const match = content.match(pattern);
          if (match) {
            newExtractedData.dob = match[1];
            break;
          }
        }
      });
    });

    // Fallback name processing if no name was found
    if (!newExtractedData.fullName && nameLines.length > 0) {
      // Use the first line that looks like a name
      const mostLikelyName = nameLines[0]
        .split(',')
        .map(part => part.trim())
        .reverse()
        .join(' ')
        .replace(/\s+/g, ' ')
        .trim();
      newExtractedData.fullName = mostLikelyName;
    }

    // Clean up the extracted name
    if (newExtractedData.fullName) {
      newExtractedData.fullName = newExtractedData.fullName
        .replace(/\s+/g, ' ')  // Remove extra spaces
        .replace(/[^\w\s]/g, '')  // Remove special characters
        .trim();
    }

    console.log('Extracted Data:', newExtractedData);
    setExtractedData(newExtractedData);
  };

  return (
    <Container maxWidth="xs" sx={{ textAlign: 'center', padding: 2 }}>
      <Typography variant="h6" gutterBottom>Driver's License</Typography>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Button color="error" startIcon={<CloseIcon />} sx={{ textTransform: 'none' }}>Cancel</Button>
        <Button color="primary" startIcon={<EditIcon />} sx={{ textTransform: 'none' }} onClick={() => setImagePreviewUrl(existingLicenseUrl)}>Edit Photo</Button>
        <Button color="success" endIcon={<DoneIcon />} sx={{ textTransform: 'none' }}>Done</Button>
      </div>

      <div>
        <h2>Driving License Upload</h2>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <Button onClick={handleUpload} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
        {uploadMessage && <p>{uploadMessage}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>

      <div>
        <h2>Driving License Details</h2>
        {Object.keys(extractedData).length > 0 ? (
          <div>
            {/* <p><strong>Name:</strong> {extractedData.fullName || "N/A"}</p> */}
            <p><strong>Address:</strong> {extractedData.streetAddress || "N/A"}</p>
            <p><strong>City:</strong> {extractedData.city || "N/A"}</p>
            <p><strong>State:</strong> {extractedData.state || "N/A"}</p>
            <p><strong>ZIP Code:</strong> {extractedData.zipCode || "N/A"}</p>
            <p><strong>Date of Birth:</strong> {extractedData.dob || "N/A"}</p>
          </div>
        ) : (
          <p>No data available.</p>
        )}
      </div>

      {imagePreviewUrl && (
        <div>
          <h4>Preview:</h4>
          <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '100%' }} />
        </div>
      )}
    </Container>
  );
};

export default DrivingLicenseUploadPage;